export const BASE_URL = 'https://admin.tampaayyappatemple.org/epooja/api/';
// export const BASE_URL = 'https://epooja-api.triamiz.in/api/';
// export const BASE_URL = 'http://localhost/triamiz/e-pooja/e-pooja-backend-final/api/';

export const END_POINTS = {
  HOME_SCREEN: `${BASE_URL}home-screen`,
  GET_PRODUCTS: `${BASE_URL}products`,
  CREATE_ORDER: `${BASE_URL}order/create`,
  GET_MANDALA_EVENT: `${BASE_URL}mandala-season/`,
  SAVE_MANDALAM_EVENT: `${BASE_URL}mandalaSeasonRegister`,
  SAVE_MANDALAM_EVENT_TYPE2: `${BASE_URL}mandalaSeasonRegisterType2`,
  GET_NATCHATHIRAM_DETAILS: `${BASE_URL}natchathiram-details`,
  LIFE_MEMBERSHIP_REGISTRATION: `${BASE_URL}lifetimeMembershipRegister`,
  PADI_SPONSERSHIP_REGISTRATION: `${BASE_URL}padiMembershipRegister`,
  GET_VOLUNTEER_DATA: `${BASE_URL}volunteer-data`,
  SAVE_VOLUNTEER_REGISTRATION: `${BASE_URL}volunteer/create`,
  GET_ONAM_SADYA_DETAILS: `${BASE_URL}onam-sadya`,
  SAVE_ONAM_SADYA_DETAILS: `${BASE_URL}onam-sadya/create`,
  GET_CONTEST_DATA: `${BASE_URL}contest-data`,
  SAVE_CONTEST_REGISTRATION: `${BASE_URL}contest/create`,
  GET_CANTEEN_DATA: `${BASE_URL}canteen-data`,
  GET_CANTEEN_DATA2: `${BASE_URL}canteen-data2`,
  SAVE_CANTEEN: `${BASE_URL}canteen/create`,
};
