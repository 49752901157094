export const colors = {
  primaryOrange: '#ff8f00',
  secondaryOrange: '#F9EFE0',
  darkBG: '#230A10',
  grey30: '#6f6464',
  grey10: '#b3b2b2',
  white: '#fff',
  appbarBg: '#090303',
  darkBrown: '#090303',
  danger: '#D32F2F',
};
