import { useState } from 'react';
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import FormInputSelectComponent from '@components/atoms/form-input/form-input-select/form-input-select.component';
import InputFastField from '@components/InputFastField';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import mandalamScreenService from '@screens/mandalam/mandalam.component.service';
import { states } from '@constants/state';
import { BASE_URL } from '@constants/api.constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    padding: theme.spacing(2, 2.5),
  },
  mt20: {
    marginTop: '10px',
    '& .MuiButton-root:hover': {
      backgroundColor: '#fd3550',
    },
  },
  button: {
    minWidth: '33px',
    backgroundColor: '#e80f2c',
    color: '#fff',
  },
  addButton: {
    fontSize: 13,
    padding: 4,
    fontWeight: 'bold',
  },
  emptyLine: {
    border: '1px solid #eee',
    marginBottom: 20,
  },
}));

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  city: '',
  state: '',
  zipCode: '',
  nachathiram: '',
  abhishekam_date: '',
  gothram: '',
  full_name: '',
  family: '',
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  mobile: Yup.number()
    .integer()
    .typeError('Please enter a valid phone number')
    .required('Mobile is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipCode: Yup.string().required('Zipcode is required'),
  nachathiram: Yup.string().required('Please select natchathiram'),
  abhishekam_date: Yup.string().required('Select Date'),
  gothram: Yup.string().required('Gothram is required'),
  full_name: Yup.string().required('Name is required'),
});

export default function TypeFourFormComponent({ eventDetails, eventId, amount, nachathiramData }) {
  const { event } = useParams();
  const classes = useStyles();
  const [nachathiramDate, setNachathiramDate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const submitDetails = async (values) => {
    setIsLoading(true);
    let details = [];
    details.push({
      nachathiram: values.nachathiram,
      abhishekam_date: values.abhishekam_date,
      gothram: values.gothram,
      name: values.full_name,
      family: values.family,
    });
    const payload = {
      customer_name: values.firstName,
      customer_email: values.email,
      customer_mobile: values.mobile,
      customer_city: values.city,
      customer_state: values.state,
      customer_zipcode: values.zipCode,
      event_id: eventId,
      event_details: JSON.stringify(details),
    };
    const response = await mandalamScreenService.saveEventDetailsType2(payload);
    if (response.status) {
      setIsLoading(true);
      let paypalUrl = BASE_URL.replace('api/', '');
      paypalUrl = paypalUrl + 'mandalam-paypal?id=' + response.data.id;
      window.location.href = paypalUrl;
    } else {
      setIsLoading(false);
      alert('Something went wrong, please try after sometime.');
    }
  };

  return (
    <div className={classes.root}>
      <Box flex={1}>
        <Grid container spacing={3}>
          <Grid item sm={12} xs={12}>
            <Paper className={classes.gridRoot} elevation={0}>
              <Typography variant="h6">Devotee Details </Typography>
              <Box my={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Formik
                      validateOnChange={false}
                      validateOnBlur={false}
                      initialValues={initialValues}
                      onSubmit={submitDetails}
                      validationSchema={validationSchema}
                    >
                      {({ touched, setFieldValue, errors, values, handleChange }) => {
                        return (
                          <Form noValidate>
                            <Box mb={3}>
                              <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    name="firstName"
                                    label="First Name"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.firstName ? errors.firstName : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    name="lastName"
                                    label="Last Name"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.lastName ? errors.lastName : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    name="email"
                                    label="Email"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.email ? errors.email : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    name="mobile"
                                    label="Phone Number"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.mobile ? errors.mobile : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    name="city"
                                    label="City"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.city ? errors.city : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <FormInputSelectComponent
                                    name="state"
                                    options={states}
                                    fullWidth
                                    placeholder="Select State"
                                    error={errors.state ? errors.state : ''}
                                  />
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    name="zipCode"
                                    label="Zip Code"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.zipCode ? errors.zipCode : ''}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <div className={classes.emptyLine}></div>
                            <Grid item sm={3} xs={12}>
                              <Typography
                                variant="h6"
                                style={{
                                  display: 'inline-flex',
                                  marginRight: '1em',
                                }}
                              >
                                Devotee
                              </Typography>
                            </Grid>
                            <Box my={2}>
                              <Grid container spacing={2} alignItems="flex-start">
                                <Grid item xs={12} sm={3}>
                                  <InputFastField
                                    name="full_name"
                                    label="Name"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.full_name ? errors.full_name : ''}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <FormInputSelectComponent
                                    name="nachathiram"
                                    options={nachathiramData}
                                    label="Select Natchathiram"
                                    fullWidth
                                    dynamicData
                                    setDateField={setNachathiramDate}
                                    placeholder="Select Nachathiram"
                                  />
                                </Grid>
                                <Grid item sm={3} xs={12}>
                                  <FormInputSelectComponent
                                    name="abhishekam_date"
                                    options={nachathiramDate}
                                    label="Select Date"
                                    fullWidth
                                    placeholder="Select Date"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <InputFastField
                                    name="gothram"
                                    label="Gothram"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.gothram ? errors.gothram : ''}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <InputFastField
                                    rows={4}
                                    name="family"
                                    label="Additional family members for Sankalpam"
                                    fullWidth
                                    multiline={true}
                                    onChange={handleChange}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <div style={{ marginBottom: 10, fontSize: 16, fontWeight: 'bold' }}>
                              Total : ${amount ?? ''}
                            </div>
                            <Box display="flex" justifyContent="center">
                              <Grid item xs={12} sm={6}>
                                <Button
                                  type="submit"
                                  color="primary"
                                  variant="contained"
                                  style={{ fontSize: 14 }}
                                  disableTouchRipple
                                  disableElevation
                                  disableRipple
                                  fullWidth
                                  disabled={isLoading}
                                >
                                  Register & Pay
                                </Button>
                              </Grid>
                            </Box>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
