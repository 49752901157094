/* eslint-disable */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import Footer from '@organisms/footer/footer.component';
import LogoComponent from '@atoms/logo/logo.component';
import AppBarComponent from '@organisms/appbar/appbar.component';
import { makeStyles } from '@material-ui/core/styles';
import FormInputAmountComponent from '@components/atoms/form-input/form-input-amount/form-input-amount.component';
import InputFastField from '@components/InputFastField';
import { BASE_URL } from '@constants/api.constants';
import {
  isBrowserValid,
  padiList,
  formatActivityResponse,
  getCanteenProductCost,
  totalCost,
} from '@utils/helpers';
import * as Yup from 'yup';

// Icons
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getCanteenData, registerCanteen, registerVolunteer } from './canteen.component.service';
import generalScreenService from '../general/general.component.service';
import FormWrapperComponent from '@components/organisms/form-wrapper/form-wrapper.component';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    padding: theme.spacing(2, 2.5),
    boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
  },
}));

const CanteenComponent = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = isBrowserValid();
  const [initialValues, setInitialValues] = useState({
    full_name: '',
    mobile: '',
    email: '',
    notes: '',
  });
  const category = '';

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required('Full Name is required'),
    email: Yup.string().required('Email is required'),
    mobile: Yup.string().required('Mobile Number is required'),
  });

  const [showOnamSadya, setShowOnamSadya] = useState({});
  const [showVolunteer, setShowVolunteer] = useState({});
  const [showContest, setShowContest] = useState({});

  const onLoad = () => {
    console.log('onLoad');
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(async () => {
    const response = await getCanteenData();
    if (response.status)
      setInitialValues({
        ...initialValues,
        canteen_categories: response?.data?.canteen_categories,
        categories: response?.data?.categories || [],
        category_product: formatActivityResponse(response?.data?.category_product) || {},
      });
    setIsLoading(false);
  }, []);

  const onSubmit = async (values, action) => {
    const total = getCanteenTotalPrice(values.categories);
    if (total <= 0) {
      alert('Please enter qty for atleast one product');
      return;
    }
    const payload = {
      name: values.full_name,
      email: values.email,
      mobile: values.mobile,
      notes: values.notes || '',
      categories: values.categories || [],
    };

    const response = await registerCanteen(payload);
    // console.log({ response });
    if (response.status) {
      let paypalUrl = BASE_URL.replace('api/', '');
      console.log({ reportId: response.data.id });
      paypalUrl = paypalUrl + 'canteen-paypal?id=' + response.data.id;
      window.location.href = paypalUrl;
    } else {
      alert(response.message);
    }
  };

  const ProductPriceView = ({ data }) => {
    if (data) {
      return (
        <>
          <div>
            {data.product_name}
            {data.price && (
              <>
                {' '}
                -{' '}
                <Typography variant="p" className="text-warning font-weight-bold ml-1">
                  ${data.price}
                </Typography>
              </>
            )}
          </div>
          {data.unit && <div className="text-muted">{data.unit}</div>}
        </>
      );
    }
    return null;
  };

  const getCanteenTotalPrice = (categories) => {
    let total = 0;
    for (let i = 0; i < categories.length; i++) {
      for (let j = 0; j < categories[i].products.length; j++) {
        const qty = categories[i].products[j].count || 0;
        total += qty * categories[i].products[j].price;
      }
    }
    return total;
  };

  const TotalCostView = ({ values }) => {
    console.log({ category: values.categories });
    return (
      <Typography variant="h6" component="span">
        Total Cost: <span>$ {getCanteenTotalPrice(values.categories || [])}</span>
      </Typography>
    );
  };

  return (
    <FormWrapperComponent
      title="Canteen"
      showOnamSadya={showOnamSadya}
      showContest={showContest}
      showVolunteer={showVolunteer}
      category={category}
    >
      <div className={classes.root}>
        <Box flex={1}>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
              <Paper className={classes.gridRoot} elevation={0}>
                <div style={{ fontSize: 16, fontWeight: 'bold' }}>DEVOTEE DETAILS</div>

                <Box my={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                      >
                        {({ errors, setFieldValue, values, handleChange }) => {
                          return (
                            <Form noValidate>
                              <Box my={2}>
                                <Grid container spacing={2}>
                                  <Grid item sm={6} xs={12}>
                                    <InputFastField
                                      id="full_name"
                                      name="full_name"
                                      label="Full Name"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      error={errors.full_name ? errors.full_name : ''}
                                    />
                                  </Grid>

                                  <Grid item sm={6} xs={12}>
                                    <InputFastField
                                      id="email"
                                      name="email"
                                      label="Email"
                                      variant="outlined"
                                      onChange={handleChange}
                                      fullWidth
                                      error={errors.email ? errors.email : ''}
                                    />
                                  </Grid>
                                  <Grid item sm={6} xs={12}>
                                    <InputFastField
                                      id="mobile"
                                      name="mobile"
                                      label="Mobile"
                                      variant="outlined"
                                      onChange={handleChange}
                                      fullWidth
                                      error={errors.mobile ? errors.mobile : ''}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                              <FieldArray
                                name="category_product"
                                render={() => (
                                  <Grid container spacing={2}>
                                    {values.categories &&
                                      values.categories.map((category, index) => (
                                        <Grid item sm={12} xs={12} key={index}>
                                          <b>{category.category_name}</b>
                                          {category.products &&
                                            category.products.length > 0 &&
                                            category.products.map((product, i) => {
                                              if (product.product_message) {
                                                return <p> {product.product_message}</p>;
                                              }
                                              return (
                                                <Grid container item sm={12} key={i} spacing={2}>
                                                  <>
                                                    <Grid
                                                      item
                                                      xs={6}
                                                      sm={3}
                                                      className="d-lg-block align-items-center"
                                                    >
                                                      <ProductPriceView data={product} />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                      <FormInputAmountComponent
                                                        id={`categories[${index}][products][${i}][count]`}
                                                        name={`categories[${index}][products][${i}][count]`}
                                                        label={'Qty'}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                        type="number"
                                                      />
                                                    </Grid>
                                                  </>
                                                </Grid>
                                              );
                                            })}
                                        </Grid>
                                      ))}
                                  </Grid>
                                )}
                              />
                              <div style={{ fontSize: 16, fontWeight: 'bold' }}>Notes:</div>
                              <Box my={2}>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="notes"
                                    name="notes"
                                    label="Notes"
                                    variant="outlined"
                                    rows={4}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline={true}
                                    error={errors.notes ? errors.notes : ''}
                                  />
                                </Grid>
                              </Box>
                              <Box>
                                <TotalCostView values={values} />
                              </Box>
                              <Box display="flex" justifyContent="center">
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    style={{ fontSize: 16 }}
                                    fullWidth
                                    disableTouchRipple
                                    disableElevation
                                    disabled={isLoading}
                                  >
                                    Register
                                  </Button>
                                </Grid>
                              </Box>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </div>
    </FormWrapperComponent>
  );
};

export default CanteenComponent;
