import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';

const PaypalComponent = () => {
  const [paid, setPaid] = useState(false);
  const [error, setError] = useState(false);
  const paypalRef = useRef();

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            intent: 'CAPTURE',
            purchase_units: [
              {
                description: 'Your description',
                amount: {
                  currency_code: 'USD',
                  value: 500.0,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          setPaid(true);
          // console.log(order);
        },
        onError: (err) => {
          //   setError(err),
          console.error(err);
        },
      })
      .render(paypalRef.current);
  }, []);

  return (
    <Box>
      <p> Make payment to confirm the service </p>
      <p> Arockia Vins </p>
      <p> $ 500 </p>
      <div ref={paypalRef} />
    </Box>
  );
};

export default PaypalComponent;
