import React from 'react';
import { isMobile } from 'react-device-detect';
import generalStyles from '../../../screens/general/general.component.style';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

const LogoComponent = () => {
  const classes = generalStyles;
  return (
    <div style={{ textAlign: 'center' }}>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => window.open('https://tampaayyappatemple.org', '_blank').focus()}
      >
        <img
          src={'https://tampaayyappatemple.org/static/sastanewlogo.cad87c07.jpeg'}
          style={{ width: 173, height: 160 }}
        />

        <h2
          style={{ marginTop: 30, marginBottom: 15 }}
          className={`${!isMobile && classes.heading}`}
        >
          Sri Ayyappa Temple of Tampa
        </h2>
      </div>

      <p style={{ marginBottom: 4 }} className="text">
        6829 Maple Lane, Tampa, FL 33610
      </p>
      <p style={{ color: 'white' }}>
        {' '}
        <PhoneIcon />
        <span style={{ marginRight: 10 }}> (813) 618-7278 </span>
      </p>
      <p style={{ color: 'white' }}>
        <EmailIcon />
        <span style={{ marginRight: 10 }}> info@tampaayyappatemple.org </span>
      </p>
      <div style={{ marginTop: 20 }} className="fund-area">
        <h3 style={{ fontSize: 35 }}>E-Pooja Services</h3>
      </div>
    </div>
  );
};

export default LogoComponent;
