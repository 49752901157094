import React from 'react';
import { Box, makeStyles, TextField } from '@material-ui/core';
import { colors } from '../../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: '#fff',
    borderRadius: '5px',
  },
  // notchedOutline: {
  //   borderWidth: '1px',
  //   borderColor: `${colors.primaryOrange} !important`,
  // },
  input: {
    // color: '#000 !important',
  },
}));

const FormInputAreaComponent = ({ type, name, id, label, value, error, rows, onChange }) => {
  const classes = useStyles();
  return (
    <Box>
      <TextField
        multiline
        rows={rows}
        value={value}
        variant={'outlined'}
        id={id}
        onChange={onChange}
        name={name}
        error={error ? true : false}
        label={label}
        style={{ width: '100%' }}
        InputProps={{
          style: { fontSize: 13 },
          classes: {
            root: classes.root,
            input: classes.input,
            // notchedOutline: classes.notchedOutline,
          },
        }}
        inputlabelprops={{
          className: classes.input,
        }}
      />
      <Box style={{ marginTop: 15, color: 'red' }}>{error}</Box>
    </Box>
  );
};

export default FormInputAreaComponent;
