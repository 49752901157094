import { NavLink } from 'react-router-dom';
import { List, ListItemIcon } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import generalStyles from '@screens/general/general.component.style';

const MenuListComponent = ({
  slug,
  menuList,
  showVolunteer,
  showContest,
  showOnamSadya,
  showCanteen,
}) => {
  const classes = generalStyles();

  return (
    <div className={classes.menuContainer}>
      <p className={classes.menuHeading}> </p>
      {menuList &&
        menuList.map((item, index) => (
          <List className={classes.list} key={index}>
            <NavLink
              to={`/services/${item.slug}`}
              className={`${
                slug ? (item.slug === slug ? 'active' : '') : index === 0 ? 'active' : ''
              }`}
            >
              <ListItemIcon className={classes.listIcon}>
                <MenuIcon />
              </ListItemIcon>
              <span className={classes.menuName}>{item.name}</span>
            </NavLink>
          </List>
        ))}
      <>
        <List className={classes.list} key={'life-time-membership'}>
          <NavLink
            to={`/life-time-membership`}
            className={`${slug ? ('life-time-membership' === slug ? 'active' : '') : ''}`}
          >
            <ListItemIcon className={classes.listIcon}>
              <MenuIcon />
            </ListItemIcon>
            <span className={classes.menuName}>Life Time Membership</span>
          </NavLink>
        </List>
        <List className={classes.list} key={'padi-sponsorship'}>
          <NavLink
            to={`/padi-sponsorship`}
            className={`${slug ? ('padi-sponsorship' === slug ? 'active' : '') : ''}`}
          >
            <ListItemIcon className={classes.listIcon}>
              <MenuIcon />
            </ListItemIcon>
            <span className={classes.menuName}> PANCHALOHA Holy 18 Steps (Padi) Sponsorship </span>
          </NavLink>
        </List>

        {showVolunteer && showVolunteer?.menu_status === 1 && (
          <List className={classes.list} key={'volunteer-registeration'}>
            <NavLink
              to={`/volunteer-registration`}
              className={`${slug ? ('volunteer-registration' === slug ? 'active' : '') : ''}`}
            >
              <ListItemIcon className={classes.listIcon}>
                <MenuIcon />
              </ListItemIcon>
              <span className={classes.menuName}>Volunteer Registration</span>
            </NavLink>
          </List>
        )}

        {showContest && showContest?.menu_status === 1 && (
          <List className={classes.list} key={'contest-registration'}>
            <NavLink
              to={`/contest-registration`}
              className={`${slug ? ('contest-registration' === slug ? 'active' : '') : ''}`}
            >
              <ListItemIcon className={classes.listIcon}>
                <MenuIcon />
              </ListItemIcon>
              <span className={classes.menuName}>Contest Registration</span>
            </NavLink>
          </List>
        )}
        {showCanteen && showCanteen?.menu_status === 1 && (
          <List className={classes.list} key={'canteen'}>
            <NavLink
              to={`/canteen`}
              className={`${slug ? ('canteen' === slug ? 'active' : '') : ''}`}
            >
              <ListItemIcon className={classes.listIcon}>
                <MenuIcon />
              </ListItemIcon>
              <span className={classes.menuName}> Canteen </span>
            </NavLink>
          </List>
        )}
        {showOnamSadya && showOnamSadya?.status === 1 && (
          <List className={classes.list} key={'onam-sadya'}>
            <NavLink
              to={`/onam-sadya`}
              className={`${slug ? ('onam-sadya' === slug ? 'active' : '') : ''}`}
            >
              <ListItemIcon className={classes.listIcon}>
                <MenuIcon />
              </ListItemIcon>
              <span className={classes.menuName}>Onam Sadya</span>
            </NavLink>
          </List>
        )}
      </>
    </div>
  );
};

export default MenuListComponent;
