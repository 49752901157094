export const states = [
  {
    id: 'Alabama',
    key: 'AL',
    name: 'Alabama',
  },
  {
    id: 'Alaska',
    key: 'AK',
    name: 'Alaska',
  },
  {
    id: 'American Samoa',
    key: 'AS',
    name: 'American Samoa',
  },
  {
    id: 'Arizona',
    key: 'AZ',
    name: 'Arizona',
  },
  {
    id: 'Arkansas',
    key: 'AR',
    name: 'Arkansas',
  },
  {
    id: 'California',
    key: 'CA',
    name: 'California',
  },
  {
    id: 'Colorado',
    key: 'CO',
    name: 'Colorado',
  },
  {
    id: 'Connecticut',
    key: 'CT',
    name: 'Connecticut',
  },
  {
    id: 'Delaware',
    key: 'DE',
    name: 'Delaware',
  },
  {
    id: 'District Of Columbia',
    key: 'DC',
    name: 'District Of Columbia',
  },
  {
    id: 'Federated States Of Micronesia',
    key: 'FM',
    name: 'Federated States Of Micronesia',
  },
  {
    id: 'Florida',
    key: 'FL',
    name: 'Florida',
  },
  {
    id: 'Georgia',
    key: 'GA',
    name: 'Georgia',
  },
  {
    id: 'Guam',
    key: 'GU',
    name: 'Guam',
  },
  {
    id: 'Hawaii',
    key: 'HI',
    name: 'Hawaii',
  },
  {
    id: 'Idaho',
    key: 'ID',
    name: 'Idaho',
  },
  {
    id: 'Illinois',
    key: 'IL',
    name: 'Illinois',
  },
  {
    id: 'Indiana',
    key: 'IN',
    name: 'Indiana',
  },
  {
    id: 'Iowa',
    key: 'IA',
    name: 'Iowa',
  },
  {
    id: 'Kansas',
    key: 'KS',
    name: 'Kansas',
  },
  {
    id: 'Kentucky',
    key: 'KY',
    name: 'Kentucky',
  },
  {
    id: 'Louisiana',
    key: 'LA',
    name: 'Louisiana',
  },
  {
    id: 'Maine',
    key: 'ME',
    name: 'Maine',
  },
  {
    id: 'Marshall Islands',
    key: 'MH',
    name: 'Marshall Islands',
  },
  {
    id: 'Maryland',
    key: 'MD',
    name: 'Maryland',
  },
  {
    id: 'Massachusetts',
    key: 'MA',
    name: 'Massachusetts',
  },
  {
    id: 'Michigan',
    key: 'MI',
    name: 'Michigan',
  },
  {
    id: 'Minnesota',
    key: 'MN',
    name: 'Minnesota',
  },
  {
    id: 'Mississippi',
    key: 'MS',
    name: 'Mississippi',
  },
  {
    id: 'Missouri',
    key: 'MO',
    name: 'Missouri',
  },
  {
    id: 'Montana',
    key: 'MT',
    name: 'Montana',
  },
  {
    id: 'Nebraska',
    key: 'NE',
    name: 'Nebraska',
  },
  {
    id: 'Nevada',
    key: 'NV',
    name: 'Nevada',
  },
  {
    id: 'New Hampshire',
    key: 'NH',
    name: 'New Hampshire',
  },
  {
    id: 'New Jersey',
    key: 'NJ',
    name: 'New Jersey',
  },
  {
    id: 'New Mexico',
    key: 'NM',
    name: 'New Mexico',
  },
  {
    id: 'New York',
    key: 'NY',
    name: 'New York',
  },
  {
    id: 'North Carolina',
    key: 'NC',
    name: 'North Carolina',
  },
  {
    id: 'North Dakota',
    key: 'ND',
    name: 'North Dakota',
  },
  {
    id: 'Northern Mariana Islands',
    key: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    id: 'Ohio',
    key: 'OH',
    name: 'Ohio',
  },
  {
    id: 'Oklahoma',
    key: 'OK',
    name: 'Oklahoma',
  },
  {
    id: 'Oregon',
    key: 'OR',
    name: 'Oregon',
  },
  {
    id: 'Palau',
    key: 'PW',
    name: 'Palau',
  },
  {
    id: 'Pennsylvania',
    key: 'PA',
    name: 'Pennsylvania',
  },
  {
    id: 'Puerto Rico',
    key: 'PR',
    name: 'Puerto Rico',
  },
  {
    id: 'Rhode Island',
    key: 'RI',
    name: 'Rhode Island',
  },
  {
    id: 'South Carolina',
    key: 'SC',
    name: 'South Carolina',
  },
  {
    id: 'South Dakota',
    key: 'SD',
    name: 'South Dakota',
  },
  {
    id: 'Tennessee',
    key: 'TN',
    name: 'Tennessee',
  },
  {
    id: 'Texas',
    key: 'TX',
    name: 'Texas',
  },
  {
    id: 'Utah',
    key: 'UT',
    name: 'Utah',
  },
  {
    id: 'Vermont',
    key: 'VT',
    name: 'Vermont',
  },
  {
    id: 'Virgin Islands',
    key: 'VI',
    name: 'Virgin Islands',
  },
  {
    id: 'Virginia',
    key: 'VA',
    name: 'Virginia',
  },
  {
    id: 'Washington',
    key: 'WA',
    name: 'Washington',
  },
  {
    id: 'West Virginia',
    key: 'WV',
    name: 'West Virginia',
  },
  {
    id: 'Wisconsin',
    key: 'WI',
    name: 'Wisconsin',
  },
  {
    id: 'Wyoming',
    key: 'WY',
    name: 'Wyoming',
  },
];
