import superCharge from '@utils/service-base';
import { END_POINTS } from '@constants/api.constants';

const { GET_PRODUCTS, CREATE_ORDER } = END_POINTS;

const indexScreenService = superCharge({
  getCategoryProducts: async () => {
    return await fetch(GET_PRODUCTS, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) return { status: true, list: data.data };
        else return { status: false, message: data.message };
      })
      .catch(function (error) {
        return { status: false, message: 'Something went wrong from login api', error };
      });
  },
  createOrder: async ({ products, userData }) => {
    const payload = {
      customer_name: userData.name,
      customer_email: userData.email,
      customer_mobile: userData.mobile,
      customer_family_name: userData.family,
      products: products,
    };
    // alert(JSON.stringify(payload));
    return await fetch(CREATE_ORDER, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) return { status: true, message: data.message, data: data.data };
        else return { status: false, message: data.message };
      })
      .catch(function (error) {
        return { status: false, message: 'Something went wrong from login api', error };
      });
  },
});

export default indexScreenService;
