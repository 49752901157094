import React from 'react';
import { colors } from '@styles/theme';
import { Box, Button } from '@material-ui/core';

const ButtonComponent = ({ text, disabled, handleButtonClick, icon, iconName }) => {
  return (
    <Button
      style={{
        backgroundColor: colors.primaryOrange,
        padding: 12,
        borderRadius: 20,
        textAlign: 'center',
        width: '100%',
        fontSize: 16,
      }}
      disabled={disabled}
      onClick={handleButtonClick}
    >
      {icon ? (
        <img style={{ marginRight: '10px' }} width="20" height="20" src={icon} alt={iconName} />
      ) : (
        ''
      )}{' '}
      {text}
    </Button>
  );
};

export default ButtonComponent;
