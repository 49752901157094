import 'date-fns';
import { at } from 'lodash';
import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { FormHelperText, makeStyles, Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker, DatePicker } from '@material-ui/pickers';
import { useField } from 'formik';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  formHelper: {
    fontSize: '1.3rem',
    color: 'red',
  },
}));

const FormInputDateComponent = (props) => {
  const {
    label,
    onChange,
    setFieldValue,
    minDate,
    maxDate,
    value,
    disabled,
    name,
    threeDays,
    onlySaturday,
    setIrumudiMinDate,
    currentDate,
    setDefaultIrumudiDate,
    allowedDates,
    allowedDays,
    restrictedDates,
    readonly = false,
    noDateChange,
  } = props;
  const styles = useStyles();
  const [selectedDate, setSelectedDate] = useState(value);
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (onChange) {
      onChange(date);
    }

    if (setFieldValue) setFieldValue(name, date);
    if (setDefaultIrumudiDate) {
      const newDate = new Date(date);
      const addOneDay = newDate.setDate(newDate.getDate() + 1);
      setIrumudiMinDate(new Date(addOneDay));
    }
  };

  const disableSpecificDays = (date) => {
    // console.log('date', date, moment(date).format('MM/DD/yyyy'), moment(date).format('dddd'));
    if (allowedDates) {
      const filteredDate = allowedDates.find((d) => d === moment(date).format('MM/DD/yyyy'));
      console.log({ filteredDate, allowedDates });
      if (!filteredDate) {
        // if (setFieldValue) setFieldValue(name, date);
        return true;
      }
    }

    if (allowedDays) {
      const filteredDay = allowedDays.find((day) => day === moment(date).format('dddd'));
      if (!filteredDay) {
        // if (setFieldValue) setFieldValue(name, date);
        return true;
      }
    }

    if (restrictedDates) {
      const filteredRestrictedDate = restrictedDates.find(
        (day) => day === moment(date).format('MM/DD/yyyy'),
      );
      if (filteredRestrictedDate) return true;
    }
  };

  function renderHelperText() {
    if (error && !value) {
      return (
        <FormHelperText className={styles.formHelper} error id={`${name}-helper-text`}>
          {error}
        </FormHelperText>
      );
    }
    return false;
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="space-around">
        <DatePicker
          inputVariant="outlined"
          name={name}
          id="date-picker-dialog"
          label={label}
          format="MM/dd/yyyy"
          value={selectedDate}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          disablePast
          onChange={handleDateChange}
          // filterDate={(d) => allowedDates.includes(moment(d).format('MM/dd/yyyy'))}
          disabled={disabled || false}
          shouldDisableDate={disableSpecificDays}
          invalidDateMessage={noDateChange ? '' : 'Invalid date'}
          minDateMessage={noDateChange ? '' : 'Invalid date'}
          maxDateMessage={noDateChange ? '' : 'Invalid date'}
          keyboardbuttonprops={{
            'aria-label': 'change date',
          }}
          readOnly={readonly}
          fullWidth
          error={error && !value && true}
        />
        {disabled && (
          <p style={{ fontSize: 10, marginRight: 30 }}> *Date cannot be changed for this service</p>
        )}
        {renderHelperText()}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default FormInputDateComponent;
