import React from 'react';
import { Box, makeStyles, TextField } from '@material-ui/core';
import { colors } from '../../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: '#fff',
    backgroundColor: '#fff',
    borderRadius: '5px',
    fontSize: 13,
  },
  notchedOutline: {
    // borderWidth: '1px',
    // borderColor: `${colors.primaryOrange} !important`,
  },
  input: {
    color: '#000 !important',
    fontSize: 14,
  },
  inputProp: {},
}));

const FormInputTextComponent = ({ type, name, id, label, value, onChange, error, autoFocus }) => {
  const classes = useStyles();
  return (
    <Box>
      <TextField
        type={type}
        variant={'outlined'}
        id={id}
        name={name}
        value={value}
        label={label}
        autoFocus={autoFocus ? autoFocus : ''}
        onChange={onChange}
        style={{ width: '100%' }}
        className={classes.inputProp}
        InputProps={{ style: { fontSize: 13 } }}
        inputlabelprops={{
          className: classes.input,
        }}
      />
      <Box style={{ marginTop: 15, color: colors.white }}>{error}</Box>
    </Box>
  );
};

export default FormInputTextComponent;
