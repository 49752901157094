import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from '@organisms/service-item/service-item.component.styles';
import { colors } from '@styles/theme';
import CalendarViewComponent from '../../components/atoms/calender-view/calendar-view.component';
import { useHistory } from 'react-router';

function BookingForm({ categoryData, addToCart, products, setProducts }) {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  const countIncrease = (index) => {
    let updatedProducts = [...products];
    updatedProducts[index].count = updatedProducts[index].count + 1;
    setProducts(updatedProducts);
  };

  const countDecrease = (index) => {
    let updatedProducts = [...products];

    if (updatedProducts[index].count > 1)
      updatedProducts[index].count = updatedProducts[index].count - 1;
    else updatedProducts[index].count = 1;

    setProducts(updatedProducts);
  };
  const handleCollapseChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const gotoMandalaSeasonDetail = (event) => {
    // console.log(event);
    history.push({ pathname: `/mandala-season/${event.slug}`, state: event });
  };

  const classes = styles();
  return (
    <Box>
      {products &&
        products.length &&
        products.map((item, index) => (
          <Accordion
            key={index}
            className={classes.paper}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleCollapseChange(`panel${index + 1}`)}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon fontSize="large" color="primary" />}
              aria-controls={`panel${index + 1}bh-content`}
              id={`panel${index + 1}bh-header`}
            >
              <Grid container>
                {item.event_date && (
                  <Grid item xs={3} style={{ marginLeft: -10 }}>
                    <CalendarViewComponent date={item.event_date} />
                  </Grid>
                )}

                <Grid item xs={item.event_date ? 7 : 10}>
                  <Typography variant="h6" className={classes.font600}>
                    {item.name}
                  </Typography>

                  {item.sub_products && item.sub_products.length == 0 && <p> {item.desc}</p>}
                </Grid>

                {item.sub_products && item.sub_products.length == 0 && (
                  <Grid item xs={2}>
                    <Typography variant="h6" style={{ textAlign: 'right' }}>
                      <b style={{ color: colors.primaryOrange, fontSize: 18 }}> ${item.amount} </b>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={3}
                className={
                  item.enabled_count === 1
                    ? classes.tableHeadingMobileSection
                    : classes.tableHeadingMobileSection1
                }
              >
                <Grid container>
                  {item.sub_products &&
                    item.sub_products.map((product, index2) => (
                      <Box
                        key={index2}
                        style={{ marginTop: index2 === 0 ? -10 : 20, width: '100%' }}
                      >
                        <Grid container>
                          <Grid item xs={10}>
                            <div>
                              <p style={{ color: colors.darkBG, fontSize: 15 }}>
                                {index2 + 1}. {product.desc}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <div>
                              <b style={{ color: colors.primaryOrange, fontSize: 18 }}>
                                ${product.amount}
                              </b>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} sm={12} style={{ marginTop: 10 }}>
                          <Button
                            fullWidth
                            onClick={() => addToCart(product)}
                            component="button"
                            color="primary"
                            size="large"
                            variant="contained"
                          >
                            <span style={{ fontSize: 14 }}> Add To Cart </span>
                          </Button>
                        </Grid>
                      </Box>
                    ))}
                </Grid>

                {item.enabled_count === 1 && (
                  <>
                    <Grid item xs={12} sm={12} className={classes.tableRow}>
                      <Grid container spacing={3}>
                        <Grid xs={3} sm={3} className={classes.buttonRow}>
                          <Button
                            component="button"
                            color="primary"
                            size="large"
                            variant="contained"
                            onClick={() => countDecrease(index)}
                          >
                            <span style={{ fontSize: 15 }}> - </span>
                          </Button>
                        </Grid>
                        <Grid xs={6} sm={6}>
                          <TextField
                            name={`qty${index + 1}`}
                            id={'qty'}
                            type="number"
                            value={item.count}
                            label="Count"
                            fullWidth
                            inputProps={{
                              min: 0,
                              style: { textAlign: 'center', fontSize: 18 },
                              labelStyles: { textAlign: 'center' },
                            }}
                          />
                        </Grid>
                        <Grid xs={3} sm={3} className={classes.buttonRow}>
                          <Button
                            component="button"
                            color="primary"
                            size="large"
                            variant="contained"
                            onClick={() => countIncrease(index)}
                          >
                            <span style={{ fontSize: 15 }}> + </span>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container xs={12} sm={12} className={classes.priceTotal}>
                      <Grid item xs={6} sm={6}>
                        <Typography variant="h6"> Price: ${item.amount} </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} style={{ textAlign: 'end' }}>
                        <Typography variant="h6">
                          {' '}
                          <b> Total: ${item.count * item.amount} </b>{' '}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {item.sub_products && item.sub_products.length == 0 && (
                  <Box width="100%">
                    {categoryData.slug === 'mandala-season' ? (
                      <Grid container xs={12} sm={12}>
                        {!item.disabled_message && (
                          <Button
                            fullWidth
                            onClick={() => gotoMandalaSeasonDetail(item)}
                            component="button"
                            color="primary"
                            size="small"
                            variant="contained"
                          >
                            <span style={{ fontSize: 14 }}> Register and Pay </span>
                          </Button>
                        )}

                        {item.disabled_message && <p> {item.disabled_message} </p>}
                      </Grid>
                    ) : (
                      <Grid container xs={12} sm={12}>
                        <Button
                          fullWidth
                          onClick={() => addToCart(item)}
                          component="button"
                          color="primary"
                          size="large"
                          variant="contained"
                        >
                          <span style={{ fontSize: 14 }}> Add To Cart </span>
                        </Button>
                      </Grid>
                    )}
                  </Box>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
}

export default BookingForm;
