import React from 'react';

const FormInputCheckBoxComponent = ({ label, id, name, value, onChange }) => {
  const handleChange = () => {
    console.log(value);
    if (onChange) onChange(!value);
  };
  return (
    <div onClick={() => handleChange()} style={{ cursor: 'pointer' }}>
      <input type="checkbox" id={id} name={name} checked={value} onChange={handleChange} />
      <span style={{ marginLeft: 10 }}> {label}</span>
    </div>
  );
};

export default FormInputCheckBoxComponent;
