import React, { useEffect, useState } from 'react';
import Footer from '@organisms/footer/footer.component';
import AppBarComponent from '@organisms/appbar/appbar.component';
import CartItems from '@screens/cart/cart-items.component';
import cartScreenService from './cart.component.service';
import { useHistory } from 'react-router-dom';
import LogoComponent from '../../components/atoms/logo/logo.component';
import { isMobile } from 'react-device-detect';
import { getStorageData, setStorageData } from '../../utils/storage';
import LoadingComponent from '../../components/organisms/loading/loading.component';

const CartScreenComponent = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const cart = getStorageData('cart_items') || [];
  let menuList = localStorage.getItem('menuList');
  menuList = menuList && JSON.parse(menuList);
  const history = useHistory();
  const [cartState, setCartState] = useState([]);
  const [services, setServices] = useState([]);

  const onload = async () => {
    const response = await cartScreenService.getGeneralItemServices(cart);
    if (response.status) {
      setCartState(response.cart);
      setServices(response.services);
    }
    setLoading(false);
  };

  const updateCartValues = (index, type, value) => {
    // console.log(index, type, value);
    let updatedCart = [...cartState];
    if (type === 'select') {
      updatedCart[index].service_id = value;
      const selectedService = updatedCart[index].services.filter((service) => service.id == value);
      updatedCart[index].service_name = (selectedService && selectedService[0].name) || '';
    } else if (type === 'date') updatedCart[index].date = value;
    setCartState(updatedCart);
    setStorageData('cart_items', updatedCart);
  };

  const removeFromCart = (index) => {
    // console.log(index);
    let updatedCart = [...cartState];

    updatedCart.splice(index, 1);
    setCartState(updatedCart);
    setStorageData('cart_items', updatedCart);
  };

  const proceedToCheckout = () => {
    // console.log('cart screen data', cartState);
    history.push({ pathname: '/checkout', state: { cart: cartState } });
  };

  useEffect(() => {
    onload();
  }, []);

  return (
    <div className="ugf-covid covid-bg">
      <div className="ugf-wraper">
        <AppBarComponent cart={cart} menuList={menuList} />
        <div className="content-block" style={isMobile ? { padding: '90px 15px 40px' } : {}}>
          <div className="logo" />
          <div className="main-content" style={{ marginTop: isMobile ? 0 : 40 }}>
            <LogoComponent />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 offset-lg-7">
              <div className="form-steps active">
                <div
                  className="form-block"
                  style={isMobile ? { padding: '30px 0px 30px 0px' } : {}}
                >
                  <div className="donation-header">
                    <h2>Cart Items</h2>
                  </div>

                  <LoadingComponent loading={loading} />
                  {!loading && (
                    <CartItems
                      cart={cartState}
                      services={services}
                      updateCart={updateCartValues}
                      proceedToCheckout={proceedToCheckout}
                      removeFromCart={removeFromCart}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CartScreenComponent;
