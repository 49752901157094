import { makeStyles } from '@material-ui/core';
import { colors } from '@styles/theme';

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'flex-start',
    alignItems: 'flex-start',
  },
  tableHeading: {
    color: colors.grey30,
  },
  tableHeadingSection: {
    paddingTop: 30,
  },
  tableRowItem: {
    fontSize: 25,
  },
  mobile: {
    order: 2,
  },
  browser: {
    order: 1,
  },
  w100: {
    width: '100%',
  },
  formMobileButton: {
    marginBottom: '4em',
  },
  cartTotal: {
    color: colors.primaryOrange,
    fontSize: 25,
    textAlign: 'center',
    marginTop: 15,
    fontWeight: 700,
  },
}));

export default useStyles;
