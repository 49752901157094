import { FormHelperText, InputLabel, FormControl, TextField } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import { useField } from 'formik';

const useStyles = makeStyles(() => ({
  input: {
    // color: '#000 !important',
    fontSize: 12,
  },
  inputProp: {},
  formHelper: {
    fontSize: '1.3rem',
  },
}));

export default function InputFastField(props) {
  const styles = useStyles();
  const {
    name,
    label,
    multiline,
    classes,
    placeholder,
    autoComplete,
    onChange,
    error,
    focus,
    disabled,
    ...rest
  } = props;
  const [field, meta] = useField(props);

  function renderHelperText() {
    if (error) {
      return (
        <FormHelperText className={styles.formHelper} error id={`${name}-helper-text`}>
          {error}
        </FormHelperText>
      );
    }
    return false;
  }

  return (
    <FormControl {...rest}>
      <TextField
        aria-describedby={error && !field.value ? `${props.name}-helper-text` : null}
        type="text"
        id={name}
        {...field}
        {...rest}
        label={label}
        variant="outlined"
        onChange={onChange}
        multiline={multiline}
        name={name}
        disabled={disabled}
        focused={focus ?? false}
        error={error ? true : false}
        classes={classes}
        inputlabelprops={{
          className: styles.input,
        }}
        placeholder={placeholder}
        inputProps={{
          'aria-label': !label ? placeholder : null,
        }}
      />
      {renderHelperText()}
    </FormControl>
  );
}
