import { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import FormInputDateComponent from '@components/atoms/form-input/form-input-date/form-input-date.component';
import FormAutoCompleteComponent from '@components/atoms/form-input/form-autocomplete/form-autocomplete.component';
import InputFastField from '@components/InputFastField';
import SelectField from '@components/SelectField';
import DeleteIcon from '@material-ui/icons/Delete';
import mandalamScreenService from '@screens/mandalam/mandalam.component.service';
import moment from 'moment';
import { BASE_URL } from '@constants/api.constants';
import {
  formOneMemberType,
  formatDate,
  malaIrumudiValidation,
  malaIrumudiUpdateValidation,
} from '@utils/helpers';
import {
  dateStringToObject,
  getAvailableDate,
  getTodayDateObject,
} from '../../../../utils/helpers';
import FormInputDateV2Component from '../../../atoms/form-input/form-input-date/form-input-date-v2.component';
import FormInputRadioComponent from '../../../atoms/form-input/form-input-radio/form-input-radio.component';
import FormInputCheckBoxComponent from '../../../atoms/form-input/form-input-checkbox/form-input-checkbox2.component';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    padding: theme.spacing(2, 2.5),
  },
  mt20: {
    marginTop: '10px',
    '& .MuiButton-root:hover': {
      backgroundColor: '#fd3550',
    },
  },
  button: {
    minWidth: '33px',
    backgroundColor: '#e80f2c',
    color: '#fff',
  },
  addButton: {
    fontSize: 13,
    padding: 4,
    fontWeight: 'bold',
  },
  emptyLine: {
    border: '1px solid #eee',
    marginBottom: 20,
  },
  total: {
    margin: '10px 0',
    fontSize: 16,
    fontWeight: 'bold',
  },
  checkbox: {
    margin: 10,
    fontSize: 16,
    width: '100%',
  },
}));

// function setDay(date, dayOfWeek) {
//   date = new Date(date.getTime());
//   date.setDate(date.getDate() + ((dayOfWeek + 7 - date.getDay()) % 7));
//   return date;
// }

function initialValues() {
  const devotees = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    city: '',
    state: '',
    whatsappNumber: '',
    whatsapp: false,
    nowhatsapp: false,
    zipCode: '',
    kanniSwamy: false,
    devotees: [
      {
        full_name: '',
        adult_or_kid: '',
        maladharnam_date: getTodayDateObject(),
        irumudi_date: getTodayDateObject(),
        kanni_swamy: false,
        going_type: 'regular',
      },
    ],
  };
  return devotees;
}

export default function TypeOneFormComponent({ event, eventId, amount }) {
  const metaData = event.meta_data ? JSON.parse(event.meta_data) : null;
  const memberTypeScheduling = event.member_type_scheduling
    ? JSON.parse(event.member_type_scheduling)
    : null;
  console.log({ memberTypeScheduling });
  const [initialValuesState, setInitialValuesState] = useState(initialValues());
  const classes = useStyles();
  const currentDate = getTodayDateObject();
  const maladharanamDate = getAvailableDate(metaData, 'maladharanam');
  const irumudiDate = getAvailableDate(metaData, 'irumudi');
  const [irumudiMinDate, setIrumudiMinDate] = useState(getTodayDateObject);
  const [memberMinDate, setMemberMinDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [memberType, setMemberType] = useState('');
  const [whatsappFocus, setWhatsappFocus] = useState(false);
  const [whatsappDisabled, setWhatsappDisabled] = useState(false);
  const [validation, setValidation] = useState(malaIrumudiValidation);

  const maladharanamRestrictedDates = metaData?.restricted_dates
    ? metaData.restricted_dates.split(',')
    : null;

  const [maladharanamRestrictedDatesState, setMaladharanamRestrictedDatesState] = useState(
    maladharanamRestrictedDates,
  );
  const irumudiRestrictedDates = metaData?.irumudi_restricted_dates
    ? metaData.irumudi_restricted_dates.split(',')
    : null;
  const maladharanamAllowedDays = metaData?.enabled_days_by_week
    ? metaData.enabled_days_by_week.split(',')
    : null;
  const irumudiAllowedDays = metaData?.irumudi_enabled_days_by_week
    ? metaData.irumudi_enabled_days_by_week.split(',')
    : null;
  const maladharanamAllowedDates = metaData?.available_dates
    ? metaData.available_dates.split(',')
    : null;
  const irumudiAllowedDates = metaData?.irumudi_available_dates
    ? metaData.irumudi_available_dates.split(',')
    : null;

  const handleRenderWhatsapp = (e, setFieldValue, formikValues) => {
    setFieldValue('mobile', e.target.value);
    if (formikValues.whatsapp === true) {
      // console.log({ value: e.target.value });
      setFieldValue('whatsappNumber', e.target.value);
    }
  };

  const handleWhatsapp = (name, setFieldValue, formikValues, setErrors, errors) => {
    setFieldValue('whatsappNumber', formikValues?.mobile);
    if (name === 'whatsapp') {
      setValidation(malaIrumudiValidation);
      setErrors({ ...errors, whatsappNumber: '' });
      if (formikValues.whatsapp === true) {
        setFieldValue('whatsapp', false);
        setFieldValue('whatsappNumber', '');
      } else {
        setFieldValue('whatsapp', true);
        setWhatsappFocus(true);
        setWhatsappDisabled(false);
        setFieldValue('nowhatsapp', false);
        setFieldValue('whatsappNumber', formikValues.mobile);
      }
    }
    if (name === 'nowhatsapp') {
      setValidation(malaIrumudiUpdateValidation);
      setErrors({ ...errors, whatsappNumber: '' });
      if (formikValues.nowhatsapp === true) {
        setFieldValue('nowhatsapp', false);
        setWhatsappFocus(true);
        setWhatsappDisabled(false);
        setFieldValue('whatsappNumber', '');
      } else {
        setFieldValue('nowhatsapp', true);
        setWhatsappFocus(false);
        setWhatsappDisabled(false);
        setFieldValue('whatsappNumber', '');
        setFieldValue('whatsapp', false);
      }
    }
  };

  const submitDetails = async (values) => {
    let dateErrorCheck = false;
    if (values.devotees) {
      for (let i = 0; i < values.devotees.length; i++) {
        const actualMinDate = calculateIrumudiMinDate(values.devotees[i], i);
        if (
          moment(values.devotees[i].irumudi_date).format('YYYY-MM-DD') <
          moment(actualMinDate).format('YYYY-MM-DD')
        ) {
          dateErrorCheck = true;
        }
      }
    }
    if (dateErrorCheck) return;
    setIsLoading(true);
    const payload = {
      customer_name: values.firstName,
      customer_email: values.email,
      customer_mobile: values.mobile,
      customer_city: values.city,
      customer_state: values.state,
      customer_zipcode: values.zipCode,
      customer_whatsapp_number: values.whatsappNumber,
      customer_has_whatsapp: values.whatsapp ? true : false,
      event_id: eventId,
      event_details: JSON.stringify(values.devotees),
    };
    // console.log({ payload });
    const response = await mandalamScreenService.saveEventDetails(payload);
    if (response.status) {
      setIsLoading(true);
      const paypalUrl = BASE_URL.replace('api/', '');
      window.location.href = paypalUrl + 'mandalam-paypal?id=' + response.data.order.id;
    } else {
      setIsLoading(false);
      alert('Something went wrong, please try after sometime.');
    }
  };

  const memberTypeDateChange = (props) => {
    const memberIrumudiDate = props && formatDate(props, memberType, memberTypeScheduling);
    console.log({ memberIrumudiDate });
    if (memberIrumudiDate) {
      setMemberMinDate(memberIrumudiDate);
    }
  };

  const calculateIrumudiMinDate = (devotee, index) => {
    // if (!memberTypeScheduling) return null;
    const memberIrumudiDate = formatDate(
      devotee.maladharnam_date,
      devotee.adult_or_kid,
      memberTypeScheduling,
    );
    console.log({ memberIrumudiDate });
    if (memberIrumudiDate) {
      return memberIrumudiDate;
    }
  };

  useEffect(() => {
    let updatedDevotees = [...initialValuesState.devotees];
    updatedDevotees[0].maladharnam_date = maladharanamDate || getTodayDateObject();
    updatedDevotees[0].irumudi_date = irumudiDate;
    console.log({ updatedDevotees });
    setInitialValuesState({ ...initialValuesState, devotees: updatedDevotees });
  }, []);

  return (
    <div className={classes.root}>
      <Box flex={1}>
        <Grid container spacing={3}>
          <Grid item sm={12} xs={12}>
            <Paper className={classes.gridRoot} elevation={0}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Typography variant="h6"> Devotee Details </Typography>
                  </Grid>
                  <Grid item sm={6} align="end"></Grid>
                </Grid>
              </Box>
              <Box my={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Formik
                      validateOnChange={false}
                      validateOnBlur={false}
                      // initialValues={{
                      //   devotees: initialValuesState.devotees,
                      // }}
                      initialValues={initialValuesState}
                      enableReinitialize
                      onSubmit={submitDetails}
                      validationSchema={validation}
                    >
                      {({ errors, setFieldValue, values, handleChange, setErrors }) => {
                        console.log({ values, errors });
                        return (
                          <Form noValidate>
                            <Box mb={3}>
                              <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.firstName ? errors.firstName : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.lastName ? errors.lastName : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.email ? errors.email : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="mobile"
                                    name="mobile"
                                    label="Phone Number"
                                    variant="outlined"
                                    onChange={(e) => handleRenderWhatsapp(e, setFieldValue, values)}
                                    fullWidth
                                    error={errors.mobile ? errors.mobile : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="city"
                                    name="city"
                                    label="City"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.city ? errors.city : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="whatsappNumber"
                                    name="whatsappNumber"
                                    label="Whatsapp Number"
                                    variant="outlined"
                                    focus={whatsappFocus}
                                    onChange={handleChange}
                                    disabled={whatsappDisabled}
                                    fullWidth
                                    error={errors.whatsappNumber ? errors.whatsappNumber : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <FormAutoCompleteComponent
                                    id="custom-autocomplete"
                                    label="States"
                                    name="state"
                                    setFieldValue={setFieldValue}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="zipCode"
                                    name="zipCode"
                                    label="Zip Code"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.zipCode ? errors.zipCode : ''}
                                  />
                                </Grid>
                              </Grid>
                            </Box>

                            <Box>
                              <Grid container spacing={1}>
                                <Grid item sm={6}>
                                  <div>
                                    <FormInputCheckBoxComponent
                                      id="whatsapp"
                                      name="whatsapp"
                                      label="Whatsapp number same as phone number"
                                      value={values.whatsapp}
                                      onChange={() => {
                                        handleWhatsapp(
                                          'whatsapp',
                                          setFieldValue,
                                          values,
                                          setErrors,
                                          errors,
                                        );
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item sm={6}>
                                  <div></div>
                                </Grid>
                                <Grid item sm={6}>
                                  <FormInputCheckBoxComponent
                                    id="nowhatsapp"
                                    name="nowhatsapp"
                                    label="No Whatsapp"
                                    value={values.nowhatsapp}
                                    onChange={() => {
                                      handleWhatsapp(
                                        'nowhatsapp',
                                        setFieldValue,
                                        values,
                                        setErrors,
                                        errors,
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <div className={classes.emptyLine}></div>
                            <FieldArray
                              name="devotees"
                              render={({ remove, push }) => (
                                <div>
                                  <Grid item sm={3} xs={12}>
                                    <Typography
                                      variant="h6"
                                      style={{
                                        display: 'inline-flex',
                                        marginRight: '1em',
                                      }}
                                    >
                                      Devotee
                                    </Typography>
                                  </Grid>
                                  <Box my={2}>
                                    {values.devotees.map((devotee, index) => (
                                      <Grid
                                        container
                                        key={index}
                                        spacing={2}
                                        alignItems="flex-start"
                                      >
                                        <Grid item xs={12} sm={3}>
                                          <InputFastField
                                            id={`devotees[${index}].full_name`}
                                            name={`devotees[${index}].full_name`}
                                            label="Full Name"
                                            variant="outlined"
                                            onChange={handleChange}
                                            fullWidth
                                            error={
                                              errors &&
                                              errors.devotees &&
                                              errors.devotees[index].full_name
                                                ? errors.devotees[index].full_name
                                                : ''
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <SelectField
                                            id={`devotees[${index}].adult_or_kid`}
                                            name={`devotees[${index}].adult_or_kid`}
                                            options={formOneMemberType}
                                            label="Member Type"
                                            fullWidth
                                            variant="outlined"
                                            setMemberType={setMemberType}
                                          />
                                        </Grid>
                                        <Grid item sm={2} xs={12}>
                                          <FormInputDateComponent
                                            label="Maladharanam Date"
                                            id={`devotees[${index}].maladharnam_date`}
                                            name={`devotees[${index}].maladharnam_date`}
                                            setFieldValue={setFieldValue}
                                            minDate={metaData?.mandalam_min_date || undefined}
                                            maxDate={metaData?.mandalam_max_date || undefined}
                                            setIrumudiMinDate={setIrumudiMinDate}
                                            currentDate={currentDate}
                                            setDefaultIrumudiDate
                                            value={maladharanamDate}
                                            onChange={memberTypeDateChange}
                                            allowedDates={maladharanamAllowedDates}
                                            allowedDays={maladharanamAllowedDays}
                                            restrictedDates={maladharanamRestrictedDatesState}
                                          />
                                        </Grid>
                                        <Grid item sm={2} xs={12}>
                                          <FormInputDateComponent
                                            label="Irumudi Date"
                                            id={`devotees[${index}].irumudi_date`}
                                            name={`devotees[${index}].irumudi_date`}
                                            minDate={calculateIrumudiMinDate(devotee, index)}
                                            maxDate={metaData?.irumudi_max_date || undefined}
                                            setFieldValue={setFieldValue}
                                            value={irumudiDate}
                                            allowedDates={irumudiAllowedDates}
                                            allowedDays={irumudiAllowedDays}
                                            restrictedDates={irumudiRestrictedDates}
                                            // minDate={calculateIrumudiMinDate(devotee, index)}
                                            // maxDate={'04/16/2022'}
                                            // threeDays
                                            // readonly
                                          />

                                          {/* <FormInputDateV2Component
                                            name={`devotees[${index}].irumudi_date`}
                                            label="Irumudi Date"
                                            onChange={setFieldValue}
                                            value={irumudiDate}
                                          /> */}
                                        </Grid>
                                        <Grid item sm={2} xs={12} className={classes.mt20}>
                                          <Button
                                            className={classes.button}
                                            variant="outlined"
                                            size="small"
                                            color="primary"
                                            onClick={() => remove(index)}
                                            disableTouchRipple
                                            focusRipple
                                          >
                                            <DeleteIcon />
                                          </Button>
                                        </Grid>
                                        <Box className={classes.checkbox}>
                                          <Grid container spacing={3} style={{ marginBottom: 25 }}>
                                            <Grid item sm={5} xs={12} style={{ padding: 0 }}>
                                              <div style={{ paddingLeft: 10 }}>
                                                <FormInputCheckBoxComponent
                                                  id={`devotees[${index}].kanni_swamy`}
                                                  name={`devotees[${index}].kanni_swamy`}
                                                  label={'Kanni Swamy (first time)'}
                                                  value={values.devotees[index].kanni_swamy}
                                                  onChange={(value) => {
                                                    setFieldValue(
                                                      `devotees[${index}].kanni_swamy`,
                                                      value,
                                                    );

                                                    setFieldValue(
                                                      `devotees[${index}].manikandan`,
                                                      false,
                                                    );

                                                    setFieldValue(
                                                      `devotees[${index}].going_type`,
                                                      'kanni_swammy',
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </Grid>

                                            <Grid item sm={5} xs={12} style={{ padding: 0 }}>
                                              <div style={{ paddingLeft: 10 }}>
                                                <FormInputCheckBoxComponent
                                                  id={`devotees[${index}].manikandan`}
                                                  name={`devotees[${index}].manikandan`}
                                                  label={'Manikandan (third time)'}
                                                  value={values.devotees[index].manikandan}
                                                  onChange={(value) => {
                                                    setFieldValue(
                                                      `devotees[${index}].manikandan`,
                                                      value,
                                                    );

                                                    setFieldValue(
                                                      `devotees[${index}].kanni_swamy`,
                                                      false,
                                                    );

                                                    setFieldValue(
                                                      `devotees[${index}].going_type`,
                                                      'manikandan',
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Grid>
                                    ))}
                                  </Box>
                                  <Box>
                                    <Button
                                      className={classes.addButton}
                                      type="button"
                                      color="primary"
                                      variant="outlined"
                                      onClick={() =>
                                        push({
                                          full_name: '',
                                          adult_or_kid: '',
                                          maladharnam_date: getTodayDateObject(),
                                          irumudi_date: irumudiDate,
                                          kanni_swamy: false,
                                        })
                                      }
                                      disableTouchRipple
                                    >
                                      Add +
                                    </Button>
                                  </Box>
                                  <Box>
                                    <div className={classes.total}>
                                      Total : ${values.devotees.length * amount}
                                    </div>
                                  </Box>
                                </div>
                              )}
                            />
                            <Box display="flex" justifyContent="center">
                              <Grid item xs={12} sm={6}>
                                <Button
                                  type="submit"
                                  color="primary"
                                  variant="contained"
                                  style={{ fontSize: 14 }}
                                  fullWidth
                                  disableTouchRipple
                                  disableElevation
                                  disabled={isLoading}
                                >
                                  Register & Pay
                                </Button>
                              </Grid>
                            </Box>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
