import { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import FormInputDateComponent from '@components/atoms/form-input/form-input-date/form-input-date.component';
import FormInputSelectComponent from '@components/atoms/form-input/form-input-select/form-input-select.component';
import InputFastField from '@components/InputFastField';
import SelectField from '@components/SelectField';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import mandalamScreenService from '@screens/mandalam/mandalam.component.service';
import dayjs from 'dayjs';
import { states } from '../../../../constants/state';
import { BASE_URL } from '../../../../constants/api.constants';
import FormInputAmountComponent from '../../../atoms/form-input/form-input-amount/form-input-amount.component';
import moment from 'moment-timezone';
import {
  dateStringToObject,
  getAvailableDate,
  getTodayDateObject,
} from '../../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    padding: theme.spacing(2, 2.5),
  },
  mt20: {
    marginTop: '10px',
    '& .MuiButton-root:hover': {
      backgroundColor: '#fd3550',
    },
  },
  button: {
    minWidth: '33px',
    backgroundColor: '#e80f2c',
    color: '#fff',
  },
  poojaDate: {
    marginTop: '1.5em',
  },
  addButton: {
    fontSize: 13,
    padding: 4,
    fontWeight: 'bold',
  },
  emptyLine: {
    border: '1px solid #eee',
    marginBottom: 20,
  },
}));

const initialValues = (eventDetails) => {
  return {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    city: '',
    state: '',
    zipCode: '',
    padiPoojaDate: eventDetails.event_date
      ? moment.tz(eventDetails.event_date, 'America/New_York')
      : moment.tz('America/New_York'),
    amount: eventDetails.minimum_amount ? '' : eventDetails.amount,
    minAmount: '',
    full_name: '',
    gothram: '',
    nachathiram: '',
  };
};

export default function TypeFiveFormComponent({ eventId, amount, eventDetails, nachathiramData }) {
  const metaData = eventDetails.meta_data ? JSON.parse(eventDetails.meta_data) : null;
  const eventDate = getAvailableDate(metaData, 'maladharanam');
  const { event } = useParams();
  const classes = useStyles();
  const [isDateEditable, setIsDateEditable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  console.log({ metaData, eventDetails });

  const eventAllowedDays = metaData?.enabled_days_by_week
    ? metaData.enabled_days_by_week.split(',')
    : null;

  const eventRestrictedDates = metaData?.restricted_dates
    ? metaData.restricted_dates.split(',')
    : null;

  const eventAllowedDates = metaData?.available_dates ? metaData.available_dates.split(',') : null;

  const validationSchema = (eventDetails) => {
    if (eventDetails.minimum_amount) {
      return Yup.object().shape({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        email: Yup.string().required('Email is required'),
        mobile: Yup.string().required('Mobile is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        zipCode: Yup.string().required('Zipcode is required'),
        full_name: Yup.string().required('Name is required'),
        amount:
          eventDetails?.editable_amount === 1 &&
          Yup.number()
            .min(
              eventDetails.minimum_amount,
              'Minimum contribution should be $' + eventDetails.minimum_amount,
            )
            .required('Amount is required'),
        gothram: Yup.string().required('Gothram is required'),
        nachathiram: Yup.string().required('Nachathiram is required'),
      });
    }
    return Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      email: Yup.string().required('Email is required'),
      mobile: Yup.string().required('Mobile is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      zipCode: Yup.string().required('Zipcode is required'),
      full_name: Yup.string().required('Name is required'),
      gothram: Yup.string().required('Gothram is required'),
      nachathiram: Yup.string().required('Nachathiram is required'),
    });
  };

  const submitDetails = async (values) => {
    setIsLoading(true);
    let details = [];
    details.push({
      name: values.full_name,
      gothram: values.gothram,
      nachathiram: values.nachathiram,
      family: values.family,
    });
    const payload = {
      customer_name: values.firstName,
      customer_email: values.email,
      customer_mobile: values.mobile,
      customer_city: values.city,
      customer_state: values.state,
      customer_zipcode: values.zipCode,
      padi_pooja_date: moment.tz(values.padiPoojaDate, 'America/New_York').format('YYYY-MM-DD'),
      amount: values.amount,
      event_id: eventId,
      event_details: JSON.stringify(details),
    };
    const response = await mandalamScreenService.saveEventDetailsType2(payload);
    if (response.status) {
      setIsLoading(true);
      let paypalUrl = BASE_URL.replace('api/', '');
      paypalUrl = paypalUrl + 'mandalam-paypal?id=' + response.data.id;
      window.location.href = paypalUrl;
    } else {
      setIsLoading(false);
      alert(response.message);
    }
  };

  useEffect(() => {
    if (eventDetails?.editable_event_date === 1) {
      setIsDateEditable(false);
    }
  }, []);

  return (
    <div className={classes.root}>
      <Box flex={1}>
        <Grid container spacing={3}>
          <Grid item sm={12} xs={12}>
            <Paper className={classes.gridRoot} elevation={0}>
              <Typography variant="h6"> Devotee Details </Typography>
              <Box my={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Formik
                      validateOnChange={false}
                      validateOnBlur={false}
                      initialValues={initialValues(eventDetails)}
                      onSubmit={submitDetails}
                      enableReinitialize
                      validationSchema={validationSchema(eventDetails)}
                    >
                      {({ getFieldProps, errors, setFieldValue, values, handleChange }) => {
                        return (
                          <Form noValidate>
                            <Box mb={3}>
                              <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    name="firstName"
                                    label="First Name"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.firstName ? errors.firstName : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    name="lastName"
                                    label="Last Name"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.lastName ? errors.lastName : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    name="email"
                                    label="Email"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.email ? errors.email : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    name="mobile"
                                    label="Phone Number"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.mobile ? errors.mobile : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    name="city"
                                    label="City"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.city ? errors.city : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <SelectField
                                    id="state"
                                    name="state"
                                    options={states}
                                    label="Select State"
                                    variant="outlined"
                                    fullWidth
                                    error={errors.state ? errors.state : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    name="zipCode"
                                    label="Zip Code"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.zipCode ? errors.zipCode : ''}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <Box my={3}>
                              <Typography variant="h6"> {eventDetails.name} Details</Typography>
                              <Grid container spacing={2}>
                                {eventDetails?.editable_amount === 1 && (
                                  <Grid item xs={12} sm={4}>
                                    <div className={classes.poojaDate}>
                                      <FormInputAmountComponent
                                        variant={'outlined'}
                                        fullWidth
                                        id="amount"
                                        name="amount"
                                        label="Amount"
                                        value={values.amount}
                                        onChange={handleChange}
                                        error={Boolean(errors.amount)}
                                        helperText={errors.amount}
                                      />

                                      <p>Minimum Contribution: ${amount}</p>
                                    </div>
                                  </Grid>
                                )}
                                {eventDetails?.show_event_date === 1 && (
                                  <>
                                    <Grid item xs={12} sm={4}>
                                      <div className={classes.poojaDate}>
                                        <FormInputDateComponent
                                          disabled={isDateEditable}
                                          label={` Date`}
                                          // minDate={getTodayDateObject()}
                                          // maxDate={'01/16/2023'}
                                          minDate={metaData?.mandalam_min_date || undefined}
                                          maxDate={metaData?.mandalam_max_date || undefined}
                                          allowedDays={eventAllowedDays}
                                          restrictedDates={eventRestrictedDates}
                                          allowedDates={eventAllowedDates}
                                          name="padiPoojaDate"
                                          setFieldValue={setFieldValue}
                                          value={
                                            eventDetails.event_date
                                              ? dayjs(eventDetails.event_date)
                                              : eventDate
                                          }
                                        />
                                      </div>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </Box>
                            <div className={classes.emptyLine}></div>
                            <Grid item sm={3} xs={12}>
                              <Typography
                                variant="h6"
                                style={{
                                  display: 'inline-flex',
                                  marginRight: '1em',
                                }}
                              >
                                Devotee
                              </Typography>
                            </Grid>
                            <Box my={2}>
                              <Grid container spacing={2} alignItems="flex-start">
                                <Grid item xs={12} sm={4}>
                                  <InputFastField
                                    name={'full_name'}
                                    label="Name"
                                    onChange={handleChange}
                                    error={errors.full_name ? errors.full_name : ''}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <SelectField
                                    id="nachathiram"
                                    name="nachathiram"
                                    options={nachathiramData}
                                    label="Select nachathiram"
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <InputFastField
                                    id={'gothram'}
                                    name={'gothram'}
                                    label="Gothram"
                                    onChange={handleChange}
                                    error={errors.gothram ? errors.gothram : ''}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <InputFastField
                                    rows={4}
                                    name="family"
                                    label="Additional family members for Sankalpam"
                                    onChange={handleChange}
                                    fullWidth
                                    multiline={true}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <div
                              style={{
                                marginBottom: 10,
                                marginTop: 20,
                                fontSize: 16,
                                fontWeight: 'bold',
                              }}
                            >
                              Total : ${values.amount || amount}
                            </div>
                            <Box display="flex" justifyContent="center">
                              <Grid item xs={12} sm={6}>
                                <Button
                                  type="submit"
                                  color="primary"
                                  variant="contained"
                                  style={{ fontSize: 14 }}
                                  disableTouchRipple
                                  disableElevation
                                  fullWidth
                                  disabled={isLoading}
                                >
                                  Register & Pay
                                </Button>
                              </Grid>
                            </Box>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
