import React from 'react';
import { Link, useParams } from 'react-router-dom';
import heart from '../assets/images/big-heart.png';

export default function Complete({ location }) {
  const urlSearchParams = new URLSearchParams(location.search);
  // console.log(urlSearchParams);

  // console.log(urlSearchParams.get('id'));

  const orderID = location?.state?.status?.order?.id || urlSearchParams.get('id');
  const customerName = location?.state?.customer?.name || urlSearchParams.get('name');

  return (
    <div className="ugf-bg">
      <div className="final-content">
        <div className="icon">
          <img src={heart} alt="" />
        </div>
        <h2>Thank You</h2>
        <p>
          {' '}
          Dear <b>{customerName}, </b> your service request has been booked successfully, and your
          reference number is <b>#{orderID}</b>{' '}
        </p>
        <Link to="/" className="btn">
          Back to home
        </Link>
      </div>
    </div>
  );
}
