import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, Button, AppBar, Menu, MenuItem, Badge } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { colors } from '../../../styles/theme';
import { isMobile } from 'react-device-detect';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useEffect } from 'react';
import generalScreenService from '../../../screens/general/general.component.service';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  barBackground: {
    backgroundColor: colors.appbarBg,
    padding: '0 15px 0 15px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#ead1b0',
    fontSize: '1.5rem',
    border: '1px solid #ead1b0',
  },
  title: {
    flexGrow: 1,
  },
  btnColor: {
    color: 'rgb(249, 239, 224)',
    padding: '15px',
  },
  menuList: {
    color: 'rgb(35, 10, 16)',
    fontSize: '1.5rem',
  },
}));

export default function AppBarComponent({
  cart,
  menuList,
  showOnamSadya,
  showVolunteer,
  showContest,
  showCanteen,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuVisibility, setMenuVisibility] = useState({
    menuList,
    showOnamSadya,
    showVolunteer,
    showContest,
    showCanteen,
  });

  const onLoad = async () => {
    if (isMobile) {
      const response = await generalScreenService.getGeneralScreenProducts('');
      if (response.status) {
        setMenuVisibility({
          menuList: response.menuList,
          showOnamSadya: response?.onam_sadya,
          showVolunteer: response?.volunteer,
          showContest: response?.contest,
          showCanteen: response?.canteen,
        });
        localStorage.setItem('menuList', JSON.stringify(menuList));
      }
    }
  };

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const gotoCart = (cart) => {
    history.push('/cart', { state: cart });
  };

  const processName = (name) => {
    name = name.replace(/ /g, '-');
    name = name.toLowerCase();
    return name;
  };

  const mobileMenuClick = (menuName) => {
    handleClose();
    history.push(`/services/${menuName.slug}`);
  };

  const gotoLifeTimeMemberShipo = () => {
    handleClose();
    history.push(`/life-time-membership`);
  };

  const gotoLink = (link) => {
    handleClose();
    history.push(link);
  };

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.barBackground}>
        <Toolbar>
          {isMobile && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleOpen}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" className={classes.title}>
            News
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Link to="/" className={classes.btnColor}>
              <span style={{ fontSize: 17 }}> Home </span>
            </Link>
            <Link
              to={{ pathname: '/cart', state: { cart } }}
              style={{ fontSize: 16 }}
              className={classes.btnColor}
            >
              <Badge badgeContent={(cart && cart.length) || 0} color="primary">
                <span style={{ fontSize: 17 }}> Cart </span>
              </Badge>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuVisibility.menuList &&
          menuVisibility.menuList.map((menu, index) => (
            <MenuItem
              key={index}
              className={classes.menuList}
              onClick={() => mobileMenuClick(menu)}
            >
              {menu.name}
            </MenuItem>
          ))}

        <MenuItem
          key={'life-time-membership'}
          className={classes.menuList}
          onClick={() => gotoLifeTimeMemberShipo()}
        >
          Life Time Membership
        </MenuItem>
        <MenuItem
          key={'padi-sponsorship'}
          className={classes.menuList}
          onClick={() => gotoLink('/padi-sponsorship')}
        >
          PANCHALOHA Holy 18 Steps (Padi) Sponsorship
        </MenuItem>
        {menuVisibility?.showVolunteer && menuVisibility?.showVolunteer?.menu_status === 1 && (
          <MenuItem
            key={'volunteer-registeration'}
            className={classes.menuList}
            onClick={() => gotoLink('/volunteer-registration')}
          >
            Volunteer Registration
          </MenuItem>
        )}
        {menuVisibility?.showContest && menuVisibility?.showContest?.menu_status === 1 && (
          <MenuItem
            key={'contest-registeration'}
            className={classes.menuList}
            onClick={() => gotoLink('/contest-registration')}
          >
            Contest Registration
          </MenuItem>
        )}
        {menuVisibility?.showCanteen && menuVisibility?.showCanteen?.menu_status === 1 && (
          <MenuItem
            key={'canteen'}
            className={classes.menuList}
            onClick={() => gotoLink('/canteen')}
          >
            Canteen
          </MenuItem>
        )}
        {menuVisibility?.showOnamSadya && menuVisibility?.showOnamSadya?.status === 1 && (
          <MenuItem
            key={'onam-sadya'}
            className={classes.menuList}
            onClick={() => gotoLink('/onam-sadya')}
          >
            Onam Sadya
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
