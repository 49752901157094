import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const FormTestComponent = () => {
  const validationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required('First Name is required'),
      email: Yup.string().required('Email is required'),
    });
  };
  return (
    <div>
      <h1>Contact Us</h1>
      <Formik
        initialValues={{ firstName: '', lastName: '', email: '' }}
        validationSchema={Yup.object({
          firstName: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
          lastName: Yup.string().max(20, 'Must be 20 characters or less').required('Required'),
          email: Yup.string().email('Invalid email address').required('Required'),
          amount: Yup.number().min(50).required('Required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor="firstName">First Name</label>
            <input id="firstName" type="text" {...formik.getFieldProps('firstName')} />
            {formik.errors.firstName ? <div>{formik.errors.firstName}</div> : null}

            <label htmlFor="lastName">Last Name</label>
            <input id="lastName" type="text" {...formik.getFieldProps('lastName')} />
            {formik.errors.lastName ? <div>{formik.errors.lastName}</div> : null}

            <label htmlFor="email">Email Address</label>
            <input id="email" type="email" {...formik.getFieldProps('email')} />
            {formik.errors.email ? <div>{formik.errors.email}</div> : null}

            <label htmlFor="email">Amount</label>
            <input id="amount" type="email" {...formik.getFieldProps('amount')} />
            {formik.errors.amount ? <div>{formik.errors.amount}</div> : null}

            <button type="submit">Submit</button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default FormTestComponent;
