import superCharge from '@utils/service-base';
import { END_POINTS } from '@constants/api.constants';

const { GET_PRODUCTS } = END_POINTS;

const cartScreenService = superCharge({
  getGeneralItemServices: async (cart) => {
    return await fetch(GET_PRODUCTS, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true)
          return {
            status: true,
            ...cartScreenService.filterGeneralServices(cart, data.data),
          };
        else return { status: false, message: data.message };
      })
      .catch(function (error) {
        return { status: false, message: 'Something went wrong from login api', error };
      });
  },
  filterGeneralServices: (cart, data) => {
    const homeScreen = data.filter((category) => category.category.name === 'Pooja Services');
    const services = homeScreen && homeScreen[0] && homeScreen[0].products;

    let cartData = [];
    for (let i = 0; i < cart.length; i++)
      for (let j = 0; j < cart[i].count; j++) {
        const cartItem = {
          name: cart[i].name,
          amount: cart[i].amount,
          category_id: cart[i].category_id,
          services: cart[i].services,
          desc: cart[i].desc,
          event_date: cart[i].event_date,
          service_id:
            cart[i].services && cart[i].services.length > 0 ? cart[i].services[0].id : cart[i].id,
          // service_id: cart[i].id,
          service_name: '',
          date: new Date(),
        };
        cartData.push(cartItem);
      }
    return { cart: cartData, services };
  },
  formatDate: (date) => {
    let dateStr = date.split('/');
    return `${dateStr[2]}-${dateStr[0]}-${dateStr[1]}`;
  },
});

export default cartScreenService;
