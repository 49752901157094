import superCharge from '@utils/service-base';
import { END_POINTS } from '@constants/api.constants';

const { HOME_SCREEN } = END_POINTS;

const generalScreenService = superCharge({
  getGeneralScreenProducts: async (slug) => {
    const url = slug ? `${HOME_SCREEN}/${slug}` : HOME_SCREEN;
    return await fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true)
          return {
            status: true,
            menuList: data.data.menu_list,
            category: data.data.category,
            products: generalScreenService.filterProductData(data.data.products),
            onam_sadya: data.data.onam_sadya,
            volunteer: data.data.volunteer,
            contest: data.data.contest,
            canteen: data.data.canteen,
          };
        else return { status: false, message: data.message };
      })
      .catch(function (error) {
        return { status: false, message: 'Something went wrong from login api', error };
      });
  },
  filterProductData: (products) => {
    return products.map((product) => {
      return {
        ...product,
        count: 1,
      };
    });
  },
});

export default generalScreenService;
