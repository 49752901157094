import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import FormInputTextComponent from '@atoms/form-input/form-input-text/form-input-text.component';
import FormInputAreaComponent from '@atoms/form-input/form-input-area/form-input-area.component';
import ButtonComponent from '@atoms/button/button.component';
import styles from '@screens/index/index.component.style';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import service from '@screens/index/index.component.service';
import { setStorageData } from '../../../utils/storage';
import { BASE_URL } from '../../../constants/api.constants';

const initialValues = {
  name: '',
  email: '',
  mobile: '',
  family: '',
};

const UserFormComponent = ({ cart, cartTotal }) => {
  const classes = styles();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    setIsLoading(true);
    const orderResponse = await service.createOrder({ products: cart, userData: values });
    if (orderResponse.status) {
      setIsLoading(true);
      setStorageData('cart_items', '');
      let paypalUrl = BASE_URL.replace('api/', '');
      paypalUrl = paypalUrl + 'paypal?id=' + orderResponse.data.order.id;
      window.location.href = paypalUrl;
    } else {
      setIsLoading(false);
      alert('Something went wrong, please try after sometime');
    }
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email().required('Email is required'),
    mobile: yup.string().required('Mobile is required'),
  });

  return (
    <Box>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ errors, values, touched, handleChange, handleSubmit }) => {
          return (
            <Form>
              <Box>
                <Box mt={3} px={2}>
                  <FormInputTextComponent
                    name={'name'}
                    id={'name'}
                    label={'Name'}
                    onChange={handleChange}
                    error={touched.name && errors.name ? errors.name : ''}
                  />
                </Box>

                <Box mt={3} px={2}>
                  <FormInputTextComponent
                    name={'email'}
                    id={'email'}
                    label={'Email'}
                    onChange={handleChange}
                    error={touched.email && errors.email ? errors.email : ''}
                  />
                </Box>

                <Box mt={3} px={2}>
                  <FormInputTextComponent
                    name={'mobile'}
                    id={'mobile'}
                    label={'Mobile'}
                    onChange={handleChange}
                    error={touched.mobile && errors.mobile ? errors.mobile : ''}
                  />
                </Box>

                <Box mt={3} px={2}>
                  <FormInputAreaComponent
                    label={'Family Gotra'}
                    name={'family'}
                    id={'family'}
                    onChange={handleChange}
                    rows={5}
                    error={touched.family && errors.family ? errors.family : ''}
                  />
                </Box>
              </Box>

              <Box mt={5} mb={5}>
                <ButtonComponent
                  disabled={isLoading}
                  text={`Pay Now $${cartTotal}`}
                  handleButtonClick={() => handleSubmit()}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default UserFormComponent;
