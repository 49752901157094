/* eslint-disable */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import Footer from '@organisms/footer/footer.component';
import LogoComponent from '@atoms/logo/logo.component';
import AppBarComponent from '@organisms/appbar/appbar.component';
import SelectField from '@components/SelectField';
import { makeStyles } from '@material-ui/core/styles';
import FormAutoCompleteComponent from '@components/atoms/form-input/form-autocomplete/form-autocomplete.component';
import InputFastField from '@components/InputFastField';
import { isBrowserValid, padiList } from '@utils/helpers';
import { getStorageData } from '@utils/storage';
import * as Yup from 'yup';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BASE_URL } from '@constants/api.constants';
import { createPadiSponsership } from './padi-sponsership.component.service';
import mandalamScreenService from '../mandalam/mandalam.component.service';
import FormInputAreaComponent from '../../components/atoms/form-input/form-input-area/form-input-area.component';
import generalScreenService from '../general/general.component.service';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    padding: theme.spacing(2, 2.5),
    boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
  },
  mt20: {
    marginTop: '10px',
    '& .MuiButton-root:hover': {
      backgroundColor: '#fd3550',
    },
  },
  mobileContentBlock: {
    padding: '60px 15px 0 !important',
  },
  mobileMainContent: {
    '& h2': {
      marginBottom: '10px !important',
    },
  },
  mobileFormBlock: {
    paddingTop: '35px !important',
  },
  padiInfo: {
    display: 'none',
  },
  formBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '90px 0',
  },
  button: {
    minWidth: '33px',
    backgroundColor: '#e80f2c',
    color: '#fff',
  },
  addButton: {
    fontSize: 13,
    padding: 8,
    fontWeight: 'bold',
    lineHeight: '10px',
  },
  emptyLine: {
    border: '1px solid #eee',
    marginBottom: 20,
  },
}));

function initialValues() {
  const devotees = {
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    mobile: '',
    city: '',
    state: '',
    zipCode: '',
    padi_number: '',
    gothram: '',
    devotees: [
      {
        full_name: '',
        nachathiram_id: '',
      },
    ],
  };
  return devotees;
}

export default function SponsorshipComponent() {
  const event = 'Pooja Services';
  const eventId = 'idTest';
  const [isLoading, setIsLoading] = useState(false);
  const [nachathiram, setNachathiram] = useState([]);
  let amount = 0;
  const classes = useStyles();
  const currentDate = new Date();
  // const menuList = JSON.parse(localStorage.getItem('menuList'));
  const cart = getStorageData('cart_items') || [];
  const category = '';
  const [devoteeCount, setDevoteeCount] = useState(1);
  const [price, setPrice] = useState('');
  const [irumudiMinDate, setIrumudiMinDate] = useState(new Date());
  const { isMobile } = isBrowserValid();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    address: Yup.string().required('Address is required'),
    email: Yup.string().required('Email is required'),
    mobile: Yup.string().required('Mobile is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string().required('Zipcode is required'),
    padi_number: Yup.string().required('Select Padi'),
    gothram: Yup.string().required('Gothram is required '),
    devotees: Yup.array()
      .of(
        Yup.object().shape({
          full_name: Yup.string().required('Full Name is required'),
          nachathiram_id: Yup.string().required('Nachathiram is required'),
          // gothram: Yup.string().required('Gothram is required'),
        }),
      )
      .required('Must have atleast one'),
  });

  const submitDetails = async (values) => {
    setIsLoading(true);
    const padiNumber = values.padi_number.split('-')[0];
    // console.log(values, (parseInt(padiAmount)));
    // values.devotees.forEach((obj) => {
    //   obj.padi_price = obj.padi_amount.split('-')[1];
    // });
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobile: values.mobile,
      city: values.city,
      address: values.address,
      state: values.state,
      zipCode: values.zipCode,
      padi_number: padiNumber,
      gothram: values.gothram,
      memberInfo: values.devotees,
    };

    console.log(payload);

    const response = await createPadiSponsership(payload);
    if (response.status) {
      setIsLoading(true);
      // console.log(response);
      const paypalUrl = BASE_URL.replace('api/', '');
      window.location.href = paypalUrl + 'padi-sponsership-paypal?ids=' + response.data;
    } else {
      setIsLoading(false);
      alert('Something went wrong, please try after sometime.');
    }
  };

  const [menuList, setMenuList] = useState([]);
  const [showOnamSadya, setShowOnamSadya] = useState({});
  const onLoad = async () => {
    if (isMobile) {
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(async () => {
    const nachathiram = await mandalamScreenService.getNatchathiramDetails();
    if (nachathiram.status) setNachathiram(nachathiram.data);
    setIsLoading(false);
  }, []);

  return (
    <div className="ugf-covid covid-bg">
      <div className="ugf-wraper">
        <AppBarComponent
          menuList={menuList}
          cart={cart}
          showOnamSadya={showOnamSadya}
          selectedCategory={category}
        />
        <div
          className={`content-block col-lg-4 col-sm-12 ${
            isMobile ? classes.mobileContentBlock : ''
          }`}
        >
          <div style={{ width: '100%', float: 'left', padding: '20px' }}>
            <div className="logo" />
            <div className={`main-content ${isMobile ? classes.mobileMainContent : ''}`}>
              <LogoComponent />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-4">
              <div className="form-steps active">
                <div
                  className={`col-lg-8 col-sm-12 ${classes.formBlock} ${
                    isMobile ? classes.mobileFormBlock : ''
                  }`}
                >
                  <div>
                    <div className="donation-header">
                      <Box className={classes.w100}>
                        <Grid
                          container
                          spacing="2"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={6}>
                            <Typography variant="h6">
                              PANCHALOHA Holy 18 Steps (Padi) Sponsorship{' '}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} align="end">
                            <Link to="/">
                              <ArrowBackIosIcon />
                              Back
                            </Link>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                    <div className={classes.root}>
                      <Box flex={1}>
                        <Grid container spacing={3}>
                          <Grid item sm={12} xs={12}>
                            <Paper className={classes.gridRoot} elevation={0}>
                              <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                                APPLICANT INFORMATION
                              </div>

                              <Box my={3}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12}>
                                    <Formik
                                      validateOnChange={false}
                                      validateOnBlur={false}
                                      initialValues={{
                                        devotees: initialValues().devotees,
                                      }}
                                      onSubmit={submitDetails}
                                      validationSchema={validationSchema}
                                    >
                                      {({ errors, setFieldValue, values, handleChange }) => {
                                        console.log(errors);
                                        return (
                                          <Form noValidate>
                                            <Box mb={3}>
                                              <Grid container spacing={2}>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="firstName"
                                                    name="firstName"
                                                    label="First Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    error={errors.firstName ? errors.firstName : ''}
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="lastName"
                                                    name="lastName"
                                                    label="Last Name"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={errors.lastName ? errors.lastName : ''}
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="address"
                                                    name="address"
                                                    label="Address"
                                                    variant="outlined"
                                                    multiline={true}
                                                    rows={2}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={errors.address ? errors.address : ''}
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="email"
                                                    name="email"
                                                    label="Email"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={errors.email ? errors.email : ''}
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="mobile"
                                                    name="mobile"
                                                    label="Phone Number"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={errors.mobile ? errors.mobile : ''}
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="city"
                                                    name="city"
                                                    label="City"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={errors.city ? errors.city : ''}
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  {/* <SelectField
                                                        id="state"
                                                        name="state"
                                                        options={states}
                                                        label="Select State"
                                                        variant="outlined"
                                                        onChange={handleChange}
                                                        fullWidth
                                                        error={errors.state ? errors.state : ''}
                                                    /> */}
                                                  <FormAutoCompleteComponent
                                                    id="custom-autocomplete"
                                                    label="States"
                                                    name="state"
                                                    setFieldValue={setFieldValue}
                                                    fullWidth
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="zipCode"
                                                    name="zipCode"
                                                    label="Zip Code"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={errors.zipCode ? errors.zipCode : ''}
                                                  />
                                                </Grid>
                                              </Grid>
                                            </Box>
                                            <Box>
                                              <Grid container item xs={4}>
                                                <div
                                                  style={{
                                                    fontSize: 16,
                                                    fontWeight: 'bold',
                                                    marginBottom: '1em',
                                                  }}
                                                >
                                                  Padi Information
                                                </div>
                                                <SelectField
                                                  id={`padi_number`}
                                                  name={`padi_number`}
                                                  options={padiList}
                                                  label="Select Padi"
                                                  variant="outlined"
                                                  fullWidth
                                                  style={{ marginBottom: '1.5em' }}
                                                />
                                              </Grid>
                                            </Box>
                                            {/* <div className={classes.emptyLine}></div> */}
                                            <FieldArray
                                              name="devotees"
                                              render={({ remove, push }) => (
                                                <div>
                                                  <Grid item sm={6} xs={12}>
                                                    <div
                                                      style={{ fontSize: 16, fontWeight: 'bold' }}
                                                    >
                                                      FAMILY INFORMATION
                                                    </div>
                                                  </Grid>
                                                  <Box my={2}>
                                                    {values.devotees.map((devotee, index) => (
                                                      <Grid
                                                        container
                                                        key={index}
                                                        spacing={2}
                                                        alignItems="flex-start"
                                                      >
                                                        <Grid item xs={12} sm={3}>
                                                          <InputFastField
                                                            id={`devotees[${index}].full_name`}
                                                            name={`devotees[${index}].full_name`}
                                                            label="Full Name"
                                                            variant="outlined"
                                                            onChange={handleChange}
                                                            fullWidth
                                                          />
                                                        </Grid>

                                                        <Grid item sm={3} xs={12}>
                                                          <SelectField
                                                            id={`devotees[${index}].nachathiram_id`}
                                                            name={`devotees[${index}].nachathiram_id`}
                                                            label="Select Nachathiram"
                                                            variant="outlined"
                                                            fullWidth
                                                            onChange={handleChange}
                                                            options={nachathiram}
                                                          />
                                                        </Grid>
                                                        {/* <Grid item sm={3} xs={12}>
                                                          <InputFastField
                                                            id={`devotees[${index}].gothram`}
                                                            name={`devotees[${index}].gothram`}
                                                            label="Gothram"
                                                            variant="outlined"
                                                            fullWidth
                                                          />
                                                        </Grid> */}
                                                        {/* <Grid item sm={2} xs={12}>
                                                          <SelectField
                                                            id={`devotees[${index}].padi_amount`}
                                                            name={`devotees[${index}].padi_amount`}
                                                            options={padiList}
                                                            label="Select Padi"
                                                            variant="outlined"
                                                            fullWidth
                                                          />
                                                        </Grid> */}
                                                        <Grid
                                                          item
                                                          sm={2}
                                                          xs={12}
                                                          className={classes.mt20}
                                                        >
                                                          <Button
                                                            className={classes.button}
                                                            variant="outlined"
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => remove(index)}
                                                            disableTouchRipple
                                                            focusRipple
                                                          >
                                                            <DeleteIcon />
                                                          </Button>
                                                        </Grid>
                                                      </Grid>
                                                    ))}
                                                  </Box>

                                                  <Box>
                                                    <Button
                                                      className={classes.addButton}
                                                      type="button"
                                                      color="primary"
                                                      variant="outlined"
                                                      onClick={() => {
                                                        if (values.devotees.length < 4) {
                                                          push({
                                                            full_name: '',
                                                            nachathiram_id: '',
                                                            gothram: '',
                                                          });
                                                        }
                                                      }}
                                                      disableTouchRipple
                                                    >
                                                      + Add Family Member
                                                    </Button>

                                                    <Grid
                                                      item
                                                      sm={12}
                                                      xs={12}
                                                      className={classes.mt20}
                                                    >
                                                      <Box mt={3}>
                                                        <FormInputAreaComponent
                                                          label="Gothram"
                                                          name="gothram"
                                                          id="gothram"
                                                          onChange={handleChange}
                                                          rows={5}
                                                          error={
                                                            errors.gothram ? errors.gothram : ''
                                                          }
                                                        />
                                                      </Box>
                                                    </Grid>
                                                  </Box>
                                                  <div className={classes.padiInfo}>
                                                    {' '}
                                                    {(amount = 0)}
                                                    {/* {values.devotees.forEach((obj) => {
                                                      let splitArry = obj?.padi_amount.split('-');
                                                      amount =
                                                        parseInt(amount) + parseInt(splitArry[1]);
                                                      obj.padi_number = parseInt(splitArry[0]);
                                                    })} */}
                                                  </div>
                                                  <div
                                                    className={!amount ? classes.padiInfo : ''}
                                                    style={{
                                                      marginBottom: 10,
                                                      fontSize: 13,
                                                      float: 'right',
                                                      fontWeight: 'bold',
                                                      marginRight: 100,
                                                    }}
                                                  >
                                                    Total: ${amount}
                                                  </div>

                                                  <div
                                                    style={{
                                                      marginBottom: 10,
                                                      fontSize: 13,
                                                      fontWeight: 'bold',
                                                      marginLeft: 150,
                                                    }}
                                                  >
                                                    INFORMATION
                                                  </div>
                                                  <ul
                                                    style={{
                                                      fontSize: 12,
                                                      fontWeight: 'bold',
                                                      marginLeft: 90,
                                                    }}
                                                  >
                                                    <li>PADI # 1 or 18: $5516.</li>
                                                    <li>PADI # 2 to 17: $1516.</li>
                                                  </ul>
                                                </div>
                                              )}
                                            />
                                            <Box display="flex" justifyContent="center">
                                              <Grid item xs={12} sm={6}>
                                                <Button
                                                  type="submit"
                                                  color="primary"
                                                  variant="contained"
                                                  style={{ fontSize: 14 }}
                                                  fullWidth
                                                  disableTouchRipple
                                                  disableElevation
                                                  disabled={isLoading}
                                                >
                                                  Pay Now
                                                </Button>
                                              </Grid>
                                            </Box>
                                          </Form>
                                        );
                                      }}
                                    </Formik>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
