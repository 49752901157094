import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import Footer from '@organisms/footer/footer.component';
import LogoComponent from '@atoms/logo/logo.component';
import AppBarComponent from '@organisms/appbar/appbar.component';
import { makeStyles } from '@material-ui/core/styles';

// Icons
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getStorageData } from '@utils/storage';
import { isBrowserValid } from '@utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    padding: theme.spacing(2, 2.5),
  },
  mt20: {
    marginTop: '10px',
    '& .MuiButton-root:hover': {
      backgroundColor: '#fd3550',
    },
  },
  padiInfo: {
    display: 'none',
  },
  formBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '90px 0',
  },
  button: {
    minWidth: '33px',
    backgroundColor: '#e80f2c',
    color: '#fff',
  },
  mobileContentBlock: {
    padding: '60px 15px 0 !important',
  },
  mobileMainContent: {
    '& h2': {
      marginBottom: '10px !important',
    },
  },
  mobileFormBlock: {
    paddingTop: '35px !important',
  },
  addButton: {
    fontSize: 13,
    padding: 8,
    fontWeight: 'bold',
    lineHeight: '10px',
  },
  emptyLine: {
    border: '1px solid #eee',
    marginBottom: 20,
  },
}));

const FormWrapperComponent = ({
  title,
  showOnamSadya,
  showContest,
  showVolunteer,
  showCanteen,
  category,
  children,
}) => {
  const classes = useStyles();
  const { isMobile } = isBrowserValid();
  const cart = getStorageData('cart_items') || [];
  const [menuList, setMenuList] = useState([]);

  const PageHeader = () => {
    return (
      <div className="donation-header">
        <Box className={classes.w100}>
          <Grid container spacing="2" display="flex" justifyContent="center" alignItems="center">
            <Grid item xs={6} style={{ marginBottom: 15 }}>
              <Typography variant="h6"> {title} </Typography>
            </Grid>
            <Grid item xs={6} align="end">
              <Link to="/">
                <ArrowBackIosIcon />
                Back
              </Link>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  };

  return (
    <div className="ugf-covid covid-bg">
      <div className="ugf-wraper">
        <AppBarComponent
          menuList={menuList}
          cart={cart}
          showOnamSadya={showOnamSadya}
          showContest={showContest}
          showVolunteer={showVolunteer}
          showCanteen={showCanteen}
          selectedCategory={category}
        />
        <div
          className={`content-block col-lg-4 col-sm-12 ${
            isMobile ? classes.mobileContentBlock : ''
          }`}
        >
          <div style={{ width: '100%', float: 'left', padding: '20px' }}>
            <div className="logo" />
            <div className={`main-content ${isMobile ? classes.mobileMainContent : ''}`}>
              <LogoComponent />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-4">
              <div className="form-steps active">
                <div
                  className={`col-lg-8 col-sm-12 ${classes.formBlock} ${
                    isMobile ? classes.mobileFormBlock : ''
                  }`}
                >
                  <div>
                    <PageHeader />
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default FormWrapperComponent;
