import { Field } from 'formik';

export default function CheckboxComponent(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <label>
          <input
            {...props}
            type="checkbox"
            checked={field.value === props.value}
            onChange={() => {
              if (props.value === field.value) {
                const nextValue = field.value !== props.value;
                form.setFieldValue(props.name, nextValue);
              } else {
                form.setFieldValue(props.name, props.value);
              }
            }}
          />{' '}
          {props.label ? props.label : props.value}
        </label>
      )}
    </Field>
  );
}
