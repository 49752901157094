import { useEffect } from 'react';
import { at } from 'lodash';
import { useField, useFormikContext } from 'formik';
import {
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputBase,
} from '@material-ui/core';
import { styled, makeStyles } from '@material-ui/core/styles';

const StyledLabel = styled(InputLabel)(({ theme }) => ({
    position: 'relative',
    transform: 'unset',
    marginBottom: theme.spacing(1.5),
  })),
  useStyles = makeStyles((theme) => ({
    dropdownStyle: {
      borderRadius: 8,
      // marginTop: 12,
    },
    placeholder: {
      color: '#000',
    },
    hintText: {
      marginBottom: theme.spacing(1.5),
    },
    formHelper: {
      fontSize: '1.3rem',
    },
    input: {
      fontSize: 14,
    },
  }));

function SelectField(props) {
  const {
      name,
      placeholder,
      label,
      options,
      disabled,
      isErrorOnToast,
      variant,
      setMemberType,
      ...rest
    } = props,
    [field, meta] = useField(props),
    { value: selectedValue, onChange } = field,
    [touched, error] = at(meta, 'touched', 'error'),
    /*
     * @material-ui inputBase "error" prop must be a boolean value.
     * */
    isError = error !== undefined,
    classes = useStyles(),
    { isSubmitting } = useFormikContext(),
    isToast = isErrorOnToast || false,
    Label = () => (label ? <StyledLabel htmlFor={name}>{label}</StyledLabel> : null);

  if (setMemberType) {
    setMemberType(selectedValue);
  }

  function renderHelperText() {
    if (isError) {
      return (
        <FormHelperText className={classes.formHelper} error>
          {error}
        </FormHelperText>
      );
    }
    return false;
  }

  return (
    <>
      <FormControl variant={variant} disabled={disabled} {...rest}>
        {/* {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>} */}
        <InputLabel id={name}>{label}</InputLabel>
        <Select
          labelId={name}
          id={name}
          name={name}
          {...field}
          value={selectedValue || ''}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            classes: { paper: classes.dropdownStyle },
          }}
          onChange={onChange}
          displayEmpty
          placeholder={label}
          label={label}
          className={selectedValue === undefined ? classes.placeholder : ''}
          error={isError}
          inputlabelprops={{
            className: classes.input,
          }}
        >
          {/* <MenuItem classes={{ root: classes.placeholder }}>Select</MenuItem> */}
          {options && options.length ? (
            options.map((option) => (
              <MenuItem key={option.id} value={option.id || option.option || option.nachathiram}>
                {option.option || option.name || option.nachathiram}
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Data</MenuItem>
          )}
        </Select>
        {renderHelperText()}
      </FormControl>
    </>
  );
}

export default SelectField;
