import React, { useEffect } from 'react';
import { Box, FormControl, Select, FormHelperText, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'formik';
import { at } from 'lodash';
import { formatNachathiramDate } from '@utils/helpers';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    color: '#0000 !important',
    fontSize: 14,
  },
}));

const FormInputSelectComponent = (props) => {
  const classes = useStyles();
  const { label, value, name, options, placeholder, setDateField, dynamicData, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');

  function renderHelperText() {
    if (error && !selectedValue) {
      return (
        <FormHelperText error id={`${name}-helper-text`}>
          {error}
        </FormHelperText>
      );
    }
    return false;
  }

  useEffect(() => {
    if (dynamicData && selectedValue) {
      const dates = formatNachathiramDate(options, selectedValue);
      if (dates) {
        setDateField(dates);
      } else {
        setDateField([]);
      }
    }
  }, [selectedValue]);

  return (
    <Box>
      <FormControl variant="outlined" className={classes.formControl} {...rest} {...field}>
        <Select
          {...field}
          {...rest}
          native
          id={name}
          name={name}
          value={selectedValue}
          label={label}
          variant="outlined"
          inputProps={{
            name: name,
            id: 'outlined-age-native-simple',
          }}
          inputlabelprops={{
            className: classes.input,
          }}
          placeholder={placeholder}
          error={error && !selectedValue && true}
        >
          <option value="">{placeholder ? placeholder : 'Select'}</option>
          {options &&
            options.map((option, index) => (
              <>
                <option key={index} value={option.id || option.option}>
                  {option.name || option.option}
                </option>
              </>
            ))}
        </Select>
        {renderHelperText()}
      </FormControl>
    </Box>
  );
};

export default FormInputSelectComponent;
