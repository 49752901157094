import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright © 2021 <Link to="#">tampa</Link>. All Rights Reserved
        </p>
      </div>
      <div className="social-links">
        <Link to="#">
          <i className="lab la-facebook-f"></i>
        </Link>
        <Link to="#">
          <i className="lab la-twitter"></i>
        </Link>
        <Link to="#">
          <i className="lab la-linkedin-in"></i>
        </Link>
        <Link to="#">
          <i className="lab la-instagram"></i>
        </Link>
      </div>
    </div>
  );
}
