import superCharge from '@utils/service-base';
import dayjs from 'dayjs';

const checkoutScreenService = superCharge({
  formatCartData: (cart) => {
    return (
      cart &&
      cart.map((item) => {
        return {
          product_id: item.service_id,
          date: dayjs(item.date).format('YYYY-MM-DD'),
          qty: 1,
          amount: item.amount,
        };
      })
    );
  },
});

export default checkoutScreenService;
