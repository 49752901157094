import React from 'react';
import { Box } from '@material-ui/core';
import AppBarComponent from '@organisms/appbar/appbar.component';
import UserFormComponent from '@organisms/user-form/user-form.component';
import Footer from '@organisms/footer/footer.component';
import checkoutScreenService from './checkout.component.service';
import LogoComponent from '../../components/atoms/logo/logo.component';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

const CheckoutComponent = ({ location }) => {
  console.log('Checkout location', location);
  const history = useHistory();
  const cart = location?.state?.cart || [];
  let menuList = localStorage.getItem('menuList');
  menuList = menuList && JSON.parse(menuList);
  const cartTotal = cart.reduce((a, b) => a + (b['amount'] || 0), 0);

  const formatedCart = checkoutScreenService.formatCartData(cart);

  if (formatedCart && formatedCart.length === 0) history.push('/');

  const payNow = () => {
    console.log(cart);
  };

  return (
    <Box>
      <div className="ugf-covid covid-bg">
        <div className="ugf-wraper">
          <AppBarComponent cart={cart} menuList={menuList} />
          <div className="content-block" style={isMobile ? { padding: '90px 15px 40px' } : {}}>
            <div className="logo" />
            <div className="main-content" style={{ marginTop: 30 }}>
              <LogoComponent />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-5 offset-lg-7">
                <div className="form-steps active">
                  <div
                    className="form-block"
                    style={isMobile ? { padding: '30px 0px 30px 0px' } : {}}
                  >
                    <div className="donation-header">
                      <h2>Checkout</h2>
                    </div>
                    <UserFormComponent
                      cart={formatedCart}
                      cartTotal={cartTotal}
                      userForm={true}
                      onPayNowClick={payNow}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Box>
  );
};

export default CheckoutComponent;
