/* eslint-disable */
import { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import CheckboxComponent from '@components/atoms/form-input/form-input-checkbox/form-input-checkbox.component';
import InputFastField from '@components/InputFastField';
import { isBrowserValid, totalCost } from '@utils/helpers';
import { getStorageData } from '@utils/storage';
import * as Yup from 'yup';
import { BASE_URL } from '@constants/api.constants';
import { getOnamSadyaData, saveOnamSadya } from './onam.sadya.component.service';
import generalScreenService from '../general/general.component.service';
import moment from 'moment';
import FormWrapperComponent from '@components/organisms/form-wrapper/form-wrapper.component';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    padding: theme.spacing(2, 2.5),
    boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
  },
  cost: {
    color: 'rgb(255, 143, 0)',
    fontSize: '15px',
    fontWeight: 600,
  },
  onamSadyaDate: {
    margin: '20px 0',
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  font12: {
    fontSize: '12px',
  },
}));

const OnamSadyaComponent = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [dineInCost, setDineInCost] = useState(null);
  const [takeAwayCost, setTakeAwayCost] = useState(null);
  const [dineInKidsCost, setDineInKidsCost] = useState(null);
  const [commonClosedMessage, setCommonClosedMessage] = useState(null);
  const [dineInMessage, setDineInMessage] = useState(null);
  const [takeAwayMessage, setTakeAwayMessage] = useState(null);
  const [data, setData] = useState({});
  const { isMobile } = isBrowserValid();
  const [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
  });

  const cart = getStorageData('cart_items') || [];
  const category = '';

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required'),
    mobile: Yup.string().required('Mobile Number is required'),
  });

  const [menuList, setMenuList] = useState([]);
  const [showOnamSadya, setShowOnamSadya] = useState({});
  const onLoad = async () => {
    if (isMobile) {
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(async () => {
    const response = await getOnamSadyaData();
    // console.log({ response });
    if (response.status) {
      setInitialValues({
        ...initialValues,
        dine_in_slots: response?.data?.dine_in_slots || [],
        take_away_slots: response?.data?.take_away_slots || [],
        onam_sadya_date: response?.data?.onam_sadya_date || [],
      });
      setData(response.data);
      setDineInCost(response?.dine_in_cost);
      setDineInKidsCost(response?.dining_kids_cost);
      setTakeAwayCost(response?.take_away_cost);
      setCommonClosedMessage(response?.common_closed_message);
      setDineInMessage(response?.dine_in_closed_message);
      setTakeAwayMessage(response?.take_away_closed_message);
      setIsLoading(false);
    }
  }, []);

  const onSubmit = async (values) => {
    const dineTotal = calculateDineInTotal(values);
    const takeAwayTotal = calculateTakeAwayTotal(values);

    if (dineTotal === 0 && takeAwayTotal === 0) {
      alert('Please select atleast one dine in or take away slot.');
      return;
    }

    const payload = {
      name: `${values.first_name} ${values.last_name}`,
      email: values.email,
      mobile: values.mobile,
      dine_in_slots: values.dine_in_slots ? values.dine_in_slots : [],
      take_away_slots: values.take_away_slots ? values.take_away_slots : [],
    };
    const response = await saveOnamSadya(payload);
    // console.log({ response });
    if (response.status) {
      let paypalUrl = BASE_URL.replace('api/', '');
      paypalUrl = paypalUrl + 'onam-sadya-paypal?id=' + response.data.id;
      window.location.href = paypalUrl;
    } else {
      alert(response.message);
    }
  };

  const calculateDineInTotal = (values) => {
    if (values.dine_in_slots) {
      const dineInSlots = values.dine_in_slots;
      let adultCount = 0;
      let kidsCount = 0;
      for (let index = 0; index < dineInSlots.length; index++) {
        if (dineInSlots[index].slot_count) {
          adultCount += parseInt(dineInSlots[index].slot_count);
        }
        if (dineInSlots[index].kids_slot_count) {
          kidsCount += parseInt(dineInSlots[index].kids_slot_count);
        }
      }
      const totalAdultCost = adultCount * dineInCost;
      const totalKidsCost = kidsCount * dineInKidsCost;
      const totalDineInCost = totalAdultCost + totalKidsCost;
      // console.log({ dineInSlots, totalCount, totalDineInCost });
      return totalDineInCost;
    }
    return 0;
  };

  const calculateTakeAwayTotal = (values) => {
    if (values.take_away_slots) {
      const takeAwaySlots = values.take_away_slots;
      let count = 0;
      for (let index = 0; index < takeAwaySlots.length; index++) {
        if (takeAwaySlots[index].slot_count) {
          count += parseInt(takeAwaySlots[index].slot_count);
        }
      }
      let totalTakeAwayCost = count * takeAwayCost;
      const discountPercent = data && data.onam_sadya_discount ? data.onam_sadya_discount : 0;
      const discountStartCount =
        data && data.onam_sadya_discount ? data.onam_sadya_discount_start_count : 0;
      if (count >= discountStartCount && discountPercent > 0) {
        totalTakeAwayCost -= Math.round((totalTakeAwayCost * discountPercent) / 100);
      }
      return totalTakeAwayCost;
    }
    return 0;
  };

  const getDineSlotError = (dine, slotValues) => {
    const filterValue = slotValues.find((slot) => slot.slot === dine.slot);

    // console.log({ dine, slotValues, filterValue });

    const slotCount = parseInt(filterValue?.slot_count || 0);
    const kidSlotCount = parseInt(filterValue?.kids_slot_count || 0);

    const totalDineSlotEntered = slotCount + kidSlotCount;

    // console.log({ totalDineSlotEntered });
    if (totalDineSlotEntered > dine.available) {
      return 'Entered count is greater than available';
    }
  };

  const getTakeAwaySlotError = (takeAway, slotValues) => {
    const filterValue = slotValues.find((slot) => slot.slot === takeAway.slot);

    // console.log({ dine, slotValues, filterValue });

    const slotCount = parseInt(filterValue?.slot_count || 0);

    // console.log({ totalDineSlotEntered });
    if (slotCount > takeAway.available) {
      return 'Entered count is greater than available';
    }
  };

  const getDineInSlotErrorStatus = (slotValues) => {
    if (slotValues)
      for (let i = 0; i < slotValues.length; i++) {
        const slotCount = parseInt(slotValues[i]?.slot_count || 0);
        const kidSlotCount = parseInt(slotValues[i]?.kids_slot_count || 0);

        const totalDineSlotEntered = slotCount + kidSlotCount;

        console.log({
          slot: slotValues[i].slot,
          available: slotValues[i].available,
          totalDineSlotEntered,
        });

        if (slotValues[i].available > 0 && totalDineSlotEntered > slotValues[i].available) {
          return true;
        }
      }

    return false;
  };

  const getTakeAwaySlotErrorStatus = (slotValues) => {
    if (slotValues)
      for (let i = 0; i < slotValues.length; i++) {
        const slotCount = parseInt(slotValues[i]?.slot_count || 0);

        console.log({
          slot: slotValues[i].slot,
          available: slotValues[i].available,
          slotCount,
        });

        if (slotValues[i].available > 0 && slotCount > slotValues[i].available) {
          return true;
        }
      }

    return false;
  };

  const TotalCostView = ({ values }) => {
    return (
      <Typography variant="h6" component="span">
        Total Cost:
        <Typography variant="p" className={classes.cost} style={{ fontSize: 18 }}>
          {' '}
          ${totalCost(calculateDineInTotal(values), calculateTakeAwayTotal(values))}
        </Typography>{' '}
      </Typography>
    );
  };

  const renderOnamSadyaDateView = (values) => {
    return values?.onam_sadya_date ? (
      <div className={classes.onamSadyaDate}>
        {' '}
        Onam Sadya Date :{' '}
        <span
          style={{
            fontSize: 18,
            color: 'rgb(255, 143, 0)',
          }}
        >
          {' '}
          {values.onam_sadya_date ? moment(values.onam_sadya_date).format('MM/DD/YYYY') : ''}
          {values.onam_sadya_date ? (
            <> {`( ${moment(values.onam_sadya_date).format('dddd')} )`}</>
          ) : (
            ''
          )}
        </span>
      </div>
    ) : (
      ''
    );
  };

  const renderDineInSlots = (dineInCost, dineInMessage, dineInKidsCost) => {
    if (dineInCost) {
      return (
        <div
          style={{
            fontSize: 16,
            fontWeight: 'bold',
          }}
        >
          Dine In Timeslots{' '}
          {dineInCost && !dineInMessage && (
            <>
              -{' '}
              <Typography variant="p" className={classes.cost}>
                ${dineInCost}
              </Typography>{' '}
              ( per adult ){' '}
              <Typography variant="p" className={classes.cost}>
                ${dineInKidsCost}
              </Typography>{' '}
              ( per kid )
            </>
          )}
        </div>
      );
    }
    return '';
  };

  return (
    <FormWrapperComponent title="Onam Sadya" showOnamSadya={showOnamSadya} category={category}>
      <div className={classes.root}>
        <Box flex={1}>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
              <Paper className={classes.gridRoot} elevation={0}>
                {commonClosedMessage ? (
                  commonClosedMessage
                ) : (
                  <>
                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>DEVOTEE DETAILS</div>
                    <Box my={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Formik
                            validateOnChange={false}
                            validateOnBlur={false}
                            initialValues={initialValues}
                            enableReinitialize
                            onSubmit={onSubmit}
                            validationSchema={validationSchema}
                          >
                            {({ errors, setFieldValue, values, handleChange }) => {
                              return (
                                <Form noValidate>
                                  <Box my={2}>
                                    <Grid container spacing={3}>
                                      <Grid item sm={6} xs={12}>
                                        <InputFastField
                                          id="first_name"
                                          name="first_name"
                                          label="First Name"
                                          variant="outlined"
                                          fullWidth
                                          onChange={handleChange}
                                          error={errors.first_name ? errors.first_name : ''}
                                        />
                                      </Grid>
                                      <Grid item sm={6} xs={12}>
                                        <InputFastField
                                          id="last_name"
                                          name="last_name"
                                          label="Last Name"
                                          variant="outlined"
                                          fullWidth
                                          onChange={handleChange}
                                          error={errors.last_name ? errors.last_name : ''}
                                        />
                                      </Grid>
                                      <Grid item sm={6} xs={12}>
                                        <InputFastField
                                          id="email"
                                          name="email"
                                          label="Email"
                                          variant="outlined"
                                          onChange={handleChange}
                                          fullWidth
                                          error={errors.email ? errors.email : ''}
                                        />
                                      </Grid>
                                      <Grid item sm={6} xs={12}>
                                        <InputFastField
                                          id="mobile"
                                          name="mobile"
                                          label="Mobile"
                                          variant="outlined"
                                          onChange={handleChange}
                                          fullWidth
                                          error={errors.mobile ? errors.mobile : ''}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Box>{renderOnamSadyaDateView(values)}</Box>
                                  <FieldArray
                                    name="dine_take_away_slots"
                                    render={() => (
                                      <>
                                        <Grid item sm={10} xs={12}>
                                          {renderDineInSlots(
                                            dineInCost,
                                            dineInMessage,
                                            dineInKidsCost,
                                          )}
                                        </Grid>

                                        <div className={`text-muted ${classes.font12}`}>
                                          Adult (Age 13 and above), Kid (Age 12 and below)
                                        </div>

                                        {dineInMessage ? (
                                          <Typography variant="p" className="text-muted">
                                            {dineInMessage}
                                          </Typography>
                                        ) : (
                                          values.dine_in_slots &&
                                          values.dine_in_slots.map((dine, index) => (
                                            <Box my={2}>
                                              <Grid
                                                container
                                                key={index}
                                                spacing={2}
                                                alignItems="center"
                                              >
                                                <Grid
                                                  item
                                                  xs={12}
                                                  sm={3}
                                                  // style={{ marginTop: -15 }}
                                                >
                                                  <p> Time Slot: {dine.slot} </p>
                                                </Grid>
                                                {dine.available > 0 ? (
                                                  <>
                                                    <Grid item xs={6} sm={3}>
                                                      <InputFastField
                                                        id={`dine_in_slots[${index}].slot_count`}
                                                        name={`dine_in_slots[${index}].slot_count`}
                                                        label="Adult Count"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={getDineSlotError(
                                                          dine,
                                                          values.dine_in_slots,
                                                        )}
                                                        onChange={handleChange}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                      <InputFastField
                                                        id={`dine_in_slots[${index}].kids_slot_count`}
                                                        name={`dine_in_slots[${index}].kids_slot_count`}
                                                        label="Kid Count"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={getDineSlotError(
                                                          dine,
                                                          values.dine_in_slots,
                                                        )}
                                                        onChange={handleChange}
                                                      />
                                                    </Grid>
                                                  </>
                                                ) : (
                                                  <p
                                                    style={{
                                                      color: 'red',
                                                      fontSize: 14,
                                                      fontWeight: 'bold',
                                                    }}
                                                  >
                                                    Time slot full{' '}
                                                  </p>
                                                )}
                                              </Grid>
                                            </Box>
                                          ))
                                        )}
                                        <Grid item sm={6} xs={12}>
                                          <div
                                            style={{
                                              fontSize: 16,
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            Take Away Timeslots{' '}
                                            {takeAwayCost && !takeAwayMessage && (
                                              <>
                                                -{' '}
                                                <Typography variant="p" className={classes.cost}>
                                                  ${takeAwayCost}
                                                </Typography>{' '}
                                                (per person)
                                              </>
                                            )}
                                          </div>
                                          {data && data.onam_sadya_discount > 0 && (
                                            <div className={`text-muted ${classes.font12}`}>
                                              {`Get ${data.onam_sadya_discount}% discount on ${data.onam_sadya_discount_start_count} or more take
                                                            aways`}
                                            </div>
                                          )}
                                        </Grid>
                                        {takeAwayMessage ? (
                                          <Typography variant="p" className="text-muted">
                                            {takeAwayMessage}
                                          </Typography>
                                        ) : (
                                          values.take_away_slots &&
                                          values.take_away_slots.map((takeaway, index) => (
                                            <Box my={2}>
                                              <Grid
                                                container
                                                key={index}
                                                spacing={2}
                                                alignItems="center"
                                              >
                                                <Grid item xs={12} sm={3}>
                                                  <p> Time Slot:: {takeaway.slot}</p>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                  <InputFastField
                                                    id={`take_away_slots[${index}].slot_count`}
                                                    name={`take_away_slots[${index}].slot_count`}
                                                    label="Count"
                                                    variant="outlined"
                                                    fullWidth
                                                    error={getTakeAwaySlotError(
                                                      takeaway,
                                                      values.take_away_slots,
                                                    )}
                                                    onChange={handleChange}
                                                  />
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          ))
                                        )}
                                      </>
                                    )}
                                  />
                                  <Box>
                                    <TotalCostView values={values} />
                                  </Box>
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    style={{ marginTop: 40 }}
                                  >
                                    <Grid item xs={12} sm={6}>
                                      <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        style={{ fontSize: 16 }}
                                        fullWidth
                                        disableTouchRipple
                                        disableElevation
                                        disabled={
                                          isLoading ||
                                          getDineInSlotErrorStatus(values.dine_in_slots) ||
                                          getTakeAwaySlotErrorStatus(values.take_away_slots)
                                        }
                                      >
                                        Pay
                                      </Button>
                                    </Grid>
                                  </Box>
                                </Form>
                              );
                            }}
                          </Formik>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </div>
    </FormWrapperComponent>
  );
};

export default OnamSadyaComponent;
