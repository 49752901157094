import superCharge from '@utils/service-base';
import { END_POINTS } from '@constants/api.constants';

const {
  GET_MANDALA_EVENT,
  SAVE_MANDALAM_EVENT,
  SAVE_MANDALAM_EVENT_TYPE2,
  GET_NATCHATHIRAM_DETAILS,
} = END_POINTS;

const mandalamScreenService = superCharge({
  getEventDetails: async (slug) => {
    return await fetch(`${GET_MANDALA_EVENT}${slug}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true)
          return {
            status: true,
            details: data.data,
          };
        else return { status: false, message: data.message };
      })
      .catch(function (error) {
        return { status: false, message: 'Something went wrong from login api', error };
      });
  },
  saveEventDetails: async (data) => {
    return await fetch(SAVE_MANDALAM_EVENT, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          return {
            status: data.status,
            data: data.data,
            message: data.message,
          };
        } else {
          return { status: false, message: data.message };
        }
      })
      .catch((error) => {
        return { status: false, message: 'Something went wrong from login api', error };
      });
  },
  saveEventDetailsType2: async (data) => {
    return await fetch(SAVE_MANDALAM_EVENT_TYPE2, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          return {
            status: data.status,
            data: data.data,
            message: data.message,
          };
        } else {
          return { status: false, message: data.message };
        }
      })
      .catch((error) => {
        return { status: false, message: 'Something went wrong from login api', error };
      });
  },
  getNatchathiramDetails: async (data) => {
    return await fetch(GET_NATCHATHIRAM_DETAILS, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          return {
            status: data.status,
            data: data.data,
            message: data.message,
          };
        } else {
          return { status: false, message: data.message };
        }
      })
      .catch((error) => {
        return { status: false, message: 'Something went wrong from login api', error };
      });
  },
});

export default mandalamScreenService;
