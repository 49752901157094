import 'date-fns';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const FormInputDateComponent = ({ label, onChange, minDate, value, disabled }) => {
  const [selectedDate, setSelectedDate] = useState(value);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (onChange) onChange(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="space-around">
        <KeyboardDatePicker
          inputVariant={'outlined'}
          // margin="normal"
          id="date-picker-dialog"
          label={label}
          format="MM/dd/yyyy"
          value={selectedDate}
          minDate={minDate ? minDate : null}
          onChange={handleDateChange}
          disabled={disabled}
          keyboardbuttonprops={{
            'aria-label': 'change date',
          }}
          fullWidth
        />
        {disabled && <p style={{ fontSize: 10 }}> *Date cannot be changed for this service</p>}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default FormInputDateComponent;
