import { END_POINTS } from '@constants/api.constants';

const { GET_ONAM_SADYA_DETAILS, SAVE_ONAM_SADYA_DETAILS } = END_POINTS;

export const getOnamSadyaData = async (payload) => {
  return await fetch(GET_ONAM_SADYA_DETAILS, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true)
        return {
          status: true,
          message: data.message,
          dine_in_cost: data.data?.dine_in_cost,
          dining_kids_cost: data.data?.dining_kids_cost,
          take_away_cost: data.data?.take_away_cost,
          common_closed_message: data.data?.common_closed_message,
          dine_in_closed_message: data.data?.dine_in_closed_message,
          take_away_closed_message: data.data?.take_away_closed_message,
          data: data.data,
        };
      else return { status: false, message: data.message };
    })
    .catch(function (error) {
      return { status: false, message: 'Something went wrong from login api', error };
    });
};

export const saveOnamSadya = async (payload) => {
  return await fetch(SAVE_ONAM_SADYA_DETAILS, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true) return { status: true, message: data.message, data: data.data };
      else return { status: false, message: data.message };
    })
    .catch(function (error) {
      return { status: false, message: 'Something went wrong from login api', error };
    });
};
