import React from 'react';
import { TextField, InputBase, FormHelperText, InputLabel, FormControl } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import { useField } from 'formik';
import { at } from 'lodash';
import { states } from '@constants/state';

const useStyles = makeStyles(() => ({
  formHelper: {
    fontSize: '1.3rem',
  },
}));

const FormAutoCompleteComponent = (props) => {
  const styles = useStyles();
  const { id, name, label, setFieldValue, options, ...rest } = props;
  const [field, meta] = useField(props);
  const { value } = field;
  const [touched, error] = at(meta, 'touched', 'error');

  function renderHelperText() {
    if (error && !field.value) {
      return (
        <FormHelperText className={styles.formHelper} error id={`${name}-helper-text`}>
          {error}
        </FormHelperText>
      );
    }
    return false;
  }
  return (
    <FormControl {...rest}>
      <Autocomplete
        {...rest}
        id="custom-autocomplete"
        name={name}
        options={states}
        defaultValue={value}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            aria-describedby={error && !field.value ? `${name}-helper-text` : null}
            {...params}
            variant="outlined"
            label={label}
            name={name}
            error={error && !field.value && true}
            helperText={renderHelperText()}
          />
        )}
        renderOption={(option) => option.name}
        onChange={(e, value) => {
          setFieldValue(name, value.name);
        }}
      />
    </FormControl>
  );
};

export default FormAutoCompleteComponent;
