import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Paper,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FormInputDateComponent from '../../components/atoms/form-input/form-input-date/form-input-date-cart.component';
import FormInputSelectComponent from '../../components/atoms/form-input/form-input-select/form-input-select-cart.component';
import ButtonComponent from '../../components/atoms/button/button.component';
import dayjs from 'dayjs';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    background: 'rgb(249, 239, 224)',
    color: 'rgb(35, 10, 16)',
  },
  headerFont: {
    fontSize: '1.5rem',
    fontWeight: '700',
  },
}));

const CartItem = ({ data, index, services, updateCart, removeFromCart }) => {
  const classes = useStyles();

  const handleSelectChange = (value) => {
    updateCart(index, 'select', value.target.value);
  };

  const handleDateChange = (value) => {
    updateCart(index, 'date', value);
  };

  return (
    <Box mt={2}>
      <Paper key={index} className={classes.root}>
        <Box px={2} py={1}>
          <Typography variant="h5" component="body1" className={classes.headerFont}>
            {data.name}
          </Typography>
          <Typography
            style={{ float: 'right' }}
            variant="h5"
            component="body1"
            className={classes.headerFont}
          >
            ${data.amount}
          </Typography>
          <p> {data.desc}</p>
        </Box>

        <Box px={2} py={1}>
          {data.services && data.services.length > 0 && (
            <Box style={{ marginBottom: 20 }}>
              <FormInputSelectComponent
                label={'Service'}
                onChange={handleSelectChange}
                name={'service'}
                options={data.services}
                value={data.service_id}
              />
            </Box>
          )}
          <FormInputDateComponent
            onChange={handleDateChange}
            label={'Date'}
            value={data.event_date ? dayjs(data.event_date) : new Date()}
            minDate={new Date()}
            disabled={data.event_date ? true : false}
          />

          <p
            onClick={() => removeFromCart(index)}
            style={{ textAlign: 'center', color: 'red', cursor: 'pointer' }}
          >
            {' '}
            <CloseIcon /> Remove from cart{' '}
          </p>
        </Box>
      </Paper>
    </Box>
  );
};

export default function CartItems({
  cart,
  services,
  updateCart,
  proceedToCheckout,
  removeFromCart,
}) {
  return (
    <Box>
      <Box>
        {cart &&
          cart?.map((item, index) => (
            <Box key={index}>
              <CartItem
                data={item}
                index={index}
                services={services}
                updateCart={updateCart}
                removeFromCart={removeFromCart}
              />
            </Box>
          ))}
      </Box>
      {cart && cart.length > 0 ? (
        <Box mt={2}>
          <ButtonComponent text={'Checkout'} handleButtonClick={proceedToCheckout} />
        </Box>
      ) : (
        <Box>
          {' '}
          <p> Cart seems to be empty </p>{' '}
        </Box>
      )}
    </Box>
  );
}
