import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function LoadingComponent({ loading }) {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <PulseLoader color="#000" loading={loading} size={10} />
    </div>
  );
}
