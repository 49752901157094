import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Paper, Grid, Row } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles/theme';
import ButtonComponent from '../../components/atoms/button/button.component';
import circle from '../../assets/icons/check-circle.svg';
import home from '../../assets/icons/home.svg';

const useStyles = makeStyles(() => ({
  success: {
    padding: '1em',
  },
  imgColor: {
    color: colors.primaryOrange,
  },
  paper: {
    flex: 1,
    padding: '10px',
    backgroundColor: colors.secondaryOrange,
    boxShadow: '0px -2px 7px -5px #000',
    borderRadius: '30px',
  },
  serviceText: {
    textAlign: 'center',
    fontSize: 19,
    '& h5': {
      fontWeight: 'bold',
      fontSize: 20,
      color: colors.primaryOrange,
      '& ::before': {
        content: '',
        display: 'block',
        width: '38%',
        height: '1px',
        background: 'rgba(0,0,0,0.1)',
        right: '0',
        top: '50%',
        position: 'absolute',
      },
    },
  },
  orderSection: {
    display: 'flex',
    marginTop: '16px',
    marginBottom: '24px',
    justifyContent: 'center',
  },
  heading: {
    backgroundColor: '#ffa50057',
    padding: '10px',
    borderRadius: '30px',
    width: '50%',
    color: '#bf7f09',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  quotes: {
    textAlign: 'justify',
  },
}));

const BookingStatusComponent = ({ location }) => {
  const params = useParams();
  const classes = useStyles();
  const history = useHistory();
  const orderID = location?.state?.status?.order?.id;
  const customerName = location?.state?.customer?.name;

  const testContent = {
    name: 'Alex Naveen',
    email: 'g.anthonyraj@sierradigitalinc.com',
    mobile: '+919500330961',
    family: 'Test',
    order: {
      customer_id: 4,
      family_name: 'Test',
      total: 1,
      status: 1,
      updated_at: '2021-08-07 09:48:36',
      created_at: '2021-08-07 09:48:36',
      id: 4,
    },
  };

  useEffect(() => {
    document.body.style.backgroundColor = colors.secondaryOrange;
  });
  return (
    <Box>
      <Box display="flex" justifyContent="center" className={classes.success}>
        <img width="200" height="200" src={circle} alt="success" className={classes.imgColor} />
      </Box>
      <Paper className={classes.paper} elevation={2}>
        <Box mt={2} mb={1} className={classes.serviceText}>
          <h5>Service Status</h5>
        </Box>
        <Box mt={1} className={classes.quotes}>
          Dear <b>{customerName} </b>, your service request has been booked successfully, and your
          reference number is <b>#{orderID}</b>
        </Box>
        <div className={classes.orderSection}>
          <Box className={classes.heading} mt={2} mb={3}>
            Order History
          </Box>
        </div>
        <Box mt={3} mb={3} p={1.5}>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={6}>
              Name:
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'end' }}>
              {customerName}
            </Grid>
            <Grid item xs={6}>
              Family:
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'end' }}>
              {testContent.family}
            </Grid>
          </Grid>
        </Box>
        <Box mt={5} mx={3} mb={3}>
          <ButtonComponent
            text={'Go To Homepage'}
            handleButtonClick={() => history.push('/')}
            icon={home}
            iconName={'home'}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default BookingStatusComponent;
