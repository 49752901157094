import { END_POINTS } from '@constants/api.constants';

const { GET_CONTEST_DATA, SAVE_CONTEST_REGISTRATION } = END_POINTS;

export const getContestData = async (payload) => {
  return await fetch(GET_CONTEST_DATA, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true) return { status: true, message: data.message, data: data.data };
      else return { status: false, message: data.message };
    })
    .catch(function (error) {
      return { status: false, message: 'Something went wrong from login api', error };
    });
};

export const registerContest = async (payload) => {
  return await fetch(SAVE_CONTEST_REGISTRATION, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === true) return { status: true, message: data.message };
      else return { status: false, message: data.message };
    })
    .catch(function (error) {
      return { status: false, message: 'Something went wrong from login api', error };
    });
};
