/* eslint-disable */
import { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import CheckboxComponent from '@components/atoms/form-input/form-input-checkbox/form-input-checkbox.component';
import InputFastField from '@components/InputFastField';
import { isBrowserValid, padiList, formatActivityResponse } from '@utils/helpers';
import * as Yup from 'yup';
import { getVolunteerData, registerVolunteer } from './volunteer-registeration.component.service';
import FormWrapperComponent from '@organisms/form-wrapper/form-wrapper.component';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    padding: theme.spacing(2, 2.5),
    boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
  },
}));

const VolunteerRegisterationComponent = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = isBrowserValid();
  const [initialValues, setInitialValues] = useState({
    full_name: '',
    mobile: '',
    email: '',
    notes: '',
  });
  const category = '';

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required('Full Name is required'),
    email: Yup.string().required('Email is required'),
    mobile: Yup.string().required('Mobile Number is required'),
  });

  const [showOnamSadya, setShowOnamSadya] = useState({});
  const [showVolunteer, setShowVolunteer] = useState({});
  const [showContest, setShowContest] = useState({});
  const onLoad = async () => {
    if (isMobile) {
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(async () => {
    const response = await getVolunteerData();
    if (response.status)
      setInitialValues({
        ...initialValues,
        raw_activities: response?.data?.raw_activities,
        activities: response?.data?.activities || [],
        timings: response?.data?.timings || [],
        activity_timings: formatActivityResponse(response?.data?.activity_timings) || {},
      });
    setIsLoading(false);
  }, []);

  const onSubmit = async (values, action) => {
    const payload = {
      name: values.full_name,
      email: values.email,
      mobile: values.mobile,
      notes: values.notes || '',
      activities: values.activity ? values.activity.join(',') : '',
      timings: values.timing ? values.timing.join(',') : '',
      activity_timing: values.activity_timings ? values.activity_timings : '',
    };
    const response = await registerVolunteer(payload);
    if (response.status) {
      action.resetForm();
      alert(response.message);
    } else {
      alert(response.message);
    }
  };

  return (
    <FormWrapperComponent
      title="Volunteer Registeration"
      showOnamSadya={showOnamSadya}
      showContest={showContest}
      showVolunteer={showVolunteer}
      selectedCategory={category}
    >
      <div className={classes.root}>
        <Box flex={1}>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
              <Paper className={classes.gridRoot} elevation={0}>
                <div style={{ fontSize: 16, fontWeight: 'bold' }}>DEVOTEE DETAILS</div>

                <Box my={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                      >
                        {({ errors, setFieldValue, values, handleChange }) => {
                          return (
                            <Form noValidate>
                              <Box my={2}>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="full_name"
                                    name="full_name"
                                    label="Full Name"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.full_name ? errors.full_name : ''}
                                  />
                                </Grid>
                              </Box>
                              <Box my={2}>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.email ? errors.email : ''}
                                  />
                                </Grid>
                              </Box>
                              <Box my={2}>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="mobile"
                                    name="mobile"
                                    label="Mobile"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.mobile ? errors.mobile : ''}
                                  />
                                </Grid>
                              </Box>
                              <FieldArray
                                name="activity_timings"
                                render={() => (
                                  <Grid container spacing={2}>
                                    {values.activities &&
                                      values.activities.map((activityKey, index) => (
                                        <Grid item sm={12} xs={12} key={index}>
                                          <b>{activityKey}</b>
                                          {values.activity_timings[activityKey] &&
                                            values.activity_timings[activityKey].length > 0 &&
                                            values.activity_timings[activityKey].map((data, i) => {
                                              const filteredAct = values.raw_activities.find(
                                                (act) => act.activities === activityKey,
                                              );
                                              console.log({
                                                v: values.raw_activities,
                                                filteredAct,
                                              });

                                              if (filteredAct.message) {
                                                return <p> {filteredAct.message}</p>;
                                              }
                                              return (
                                                <Grid container item sm={12} key={i}>
                                                  {data.available > 0 ? (
                                                    <CheckboxComponent
                                                      id={`activity_timings[${activityKey}][${i}][isChecked]`}
                                                      name={`activity_timings[${activityKey}][${i}][isChecked]`}
                                                      label={data.timing}
                                                      onChange={handleChange}
                                                      value={true}
                                                      checked={data.isChecked}
                                                    />
                                                  ) : (
                                                    <p>
                                                      <CheckboxComponent
                                                        id={`activity_timings[${activityKey}][${i}][isChecked]`}
                                                        name={`activity_timings[${activityKey}][${i}][isChecked]`}
                                                        label={''}
                                                        disabled
                                                        onChange={handleChange}
                                                        value={true}
                                                        checked={data.isChecked}
                                                      />{' '}
                                                      {data.timing} -{' '}
                                                      <span style={{ color: 'red' }}>
                                                        {' '}
                                                        Time slot full{' '}
                                                      </span>
                                                    </p>
                                                  )}
                                                </Grid>
                                              );
                                            })}
                                        </Grid>
                                      ))}
                                  </Grid>
                                )}
                              />
                              <div style={{ fontSize: 16, fontWeight: 'bold' }}>Notes:</div>
                              <Box my={2}>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="notes"
                                    name="notes"
                                    label="Notes"
                                    variant="outlined"
                                    rows={4}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline={true}
                                    error={errors.notes ? errors.notes : ''}
                                  />
                                </Grid>
                              </Box>
                              <Box display="flex" justifyContent="center">
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    style={{ fontSize: 16 }}
                                    fullWidth
                                    disableTouchRipple
                                    disableElevation
                                    disabled={isLoading}
                                  >
                                    Register
                                  </Button>
                                </Grid>
                              </Box>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </div>
    </FormWrapperComponent>
  );
};

export default VolunteerRegisterationComponent;
