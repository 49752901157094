import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Footer from '@organisms/footer/footer.component';
import AppBarComponent from '@organisms/appbar/appbar.component';
import { isBrowserValid } from '../../utils/helpers';
import LogoComponent from '../../components/atoms/logo/logo.component';
import TypeOneFormComponent from '../../components/organisms/mandala-season-forms/type-one-form/type-one-form.component';
import TypeTwoFormComponent from '../../components/organisms/mandala-season-forms/type-one-form/type-two-form.component';
import TypeThreeFormComponent from '../../components/organisms/mandala-season-forms/type-one-form/type-three-form.component';
import TypeFourFormComponent from '../../components/organisms/mandala-season-forms/type-one-form/type-four-form.component';
import TypeFiveFormComponent from '../../components/organisms/mandala-season-forms/type-one-form/type-five-form.component';
import TypeSixFormComponent from '../../components/organisms/mandala-season-forms/type-one-form/type-six-form.component';
import { getStorageData } from '../../utils/storage';
import generalStyles from '../general/general.component.style';
import mandalamScreenService from './mandalam.component.service';
import LoadingComponent from '../../components/organisms/loading/loading.component';
import { formatOptions } from '@utils/helpers';

const MandalamComponent = () => {
  const classes = generalStyles();
  const params = useParams();
  const location = useLocation();
  const { isMobile } = isBrowserValid();
  const [loading, setLoading] = useState(true);
  const menuList = JSON.parse(localStorage.getItem('menuList'));
  const cart = getStorageData('cart_items') || [];
  const [eventDetails, setEventDetails] = useState(null);
  const [natchathiramData, setNatchathiramData] = useState([]);
  const category = '';
  const categoryName = '';
  const eventId = location?.state?.id;
  const [eventDisabled, setEventDisabled] = useState(null);

  const onLoad = async () => {
    const eventDetail = await mandalamScreenService.getEventDetails(params?.eventName || '');
    console.log('eventDetail', eventDetail);
    if (eventDetail.status) {
      if (eventDetail.details.disabled_message)
        setEventDisabled(eventDetail.details.disabled_message);
      else setEventDetails(eventDetail.details);
    }
    setLoading(false);
  };

  const getNatchathiramData = async () => {
    const details = await mandalamScreenService.getNatchathiramDetails();
    if (details.status) setNatchathiramData(details.data);
    setLoading(false);
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    getNatchathiramData();
  }, []);

  return (
    <div className="ugf-covid covid-bg">
      <div className="ugf-wraper">
        <AppBarComponent menuList={menuList} cart={cart} selectedCategory={category} />
        <div
          className={`content-block col-lg-4 col-sm-12 ${
            isMobile ? classes.mobileContentBlock : ''
          }`}
        >
          <div style={{ width: '100%', float: 'left', padding: '20px' }}>
            <div className="logo" />
            <div className={`main-content ${isMobile ? classes.mobileMainContent : ''}`}>
              <LogoComponent />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-4">
              <div className="form-steps active">
                <div
                  className={` col-lg-8 col-sm-12 ${classes.formBlock} ${
                    isMobile ? classes.mobileFormBlock : ''
                  }`}
                >
                  {!loading && (
                    <div>
                      {eventDetails && (
                        <>
                          <div className="donation-header">
                            <Box className={classes.w100}>
                              <Grid
                                container
                                spacing="2"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item xs={6}>
                                  <Typography variant="h6">
                                    {eventDetails?.name || 'Pooja Services'}
                                  </Typography>
                                  {eventDetails?.desc && <div>{eventDetails.desc}</div>}
                                </Grid>
                                <Grid item xs={6} align="end">
                                  <Link to="/services/mandala-season">
                                    <ArrowBackIosIcon />
                                    Back
                                  </Link>
                                </Grid>
                              </Grid>
                            </Box>
                          </div>

                          <div>
                            {eventDetails.form_type === 1 && (
                              <TypeOneFormComponent
                                event={eventDetails}
                                eventId={eventDetails?.id}
                                amount={eventDetails?.amount}
                              />
                            )}

                            {eventDetails.form_type === 2 && (
                              <TypeTwoFormComponent
                                event={eventDetails}
                                eventId={eventDetails?.id}
                                amount={eventDetails?.amount}
                              />
                            )}

                            {eventDetails.form_type === 3 && (
                              <TypeThreeFormComponent
                                event={eventDetails}
                                eventId={eventDetails?.id}
                                amount={eventDetails?.amount}
                              />
                            )}
                            {eventDetails.form_type === 4 && (
                              <TypeFourFormComponent
                                eventDetails={eventDetails}
                                eventId={eventDetails?.id}
                                amount={eventDetails?.amount}
                                nachathiramData={formatOptions(
                                  natchathiramData,
                                  'id',
                                  'nachathiram',
                                )}
                              />
                            )}
                            {eventDetails.form_type === 5 && (
                              <TypeFiveFormComponent
                                eventId={eventDetails?.id}
                                amount={eventDetails?.amount}
                                eventDetails={eventDetails}
                                nachathiramData={formatOptions(
                                  natchathiramData,
                                  'id',
                                  'nachathiram',
                                )}
                              />
                            )}
                            {eventDetails.form_type === 6 && (
                              <TypeSixFormComponent
                                eventId={eventDetails?.id}
                                amount={eventDetails?.amount}
                                eventDetails={eventDetails}
                                nachathiramData={formatOptions(
                                  natchathiramData,
                                  'id',
                                  'nachathiram',
                                )}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  {eventDisabled && (
                    <div>
                      <p
                        style={{
                          fontSize: '20px',
                          fontWeight: 600,
                        }}
                      >
                        {eventDisabled}
                      </p>

                      <Link to="/services/mandala-season">
                        <ArrowBackIosIcon />
                        Back
                      </Link>
                    </div>
                  )}

                  {loading && <LoadingComponent loading={loading} />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MandalamComponent;
