import { makeStyles } from '@material-ui/core/styles';

const generalStyles = makeStyles(() => ({
  logoContainer: {
    width: '100%',
    float: 'left',
    padding: '20px',
  },
  menuContainer: {
    // width: '50%',
    background: '#ebd1b0',
    float: 'left',
    padding: '20px',
  },
  menuHeading: {
    fontSize: '2em',
    color: '#230a10',
    fontWeight: '500',
    paddingTop: '15px',
  },
  list: {
    minWidth: '300px',
    '& .active': {
      opacity: '0.7',
      padding: '10px',
      width: '100%',
      background: '#f9efe0',
      color: '#000 !important',
      borderRadius: '8px',
    },
  },
  listText: {
    '& span': {
      fontSize: '1.3em',
    },
  },
  listIcon: {
    position: 'relative !important',
    top: '4px !important',
    '& .MuiSvgIcon-root': {
      fontSize: '1.8em',
    },
  },
  menuName: {
    position: 'relative',
    bottom: '3px',
    fontSize: '16px',
  },
  mobileContentBlock: {
    padding: '60px 15px 0 !important',
  },
  mobileMainContent: {
    '& h2': {
      marginBottom: '10px !important',
    },
  },
  mobileFormBlock: {
    paddingTop: '35px !important',
  },
  heading: {
    marginTop: '20px !important',
  },
  contactText: {
    color: 'white',
  },
  formBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '90px 0',
  },
  w100: {
    width: '100%',
  },
}));

export default generalStyles;
