import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: '#fff',
    backgroundColor: '#fff',
    borderRadius: '5px',
    fontSize: 13,
  },
  notchedOutline: {
    // borderWidth: '1px',
    // borderColor: `${colors.primaryOrange} !important`,
  },
  input: {
    color: '#000 !important',
    fontSize: 14,
  },
  inputProp: {},
  error: {
    fontSize: 18,
  },
}));

const FormInputAmountComponent = (props) => {
  const classes = useStyles();
  return (
    <>
      <TextField
        {...props}
        InputProps={{ style: { fontSize: 13 }, classes: { error: classes.error } }}
        inputlabelprops={{
          className: classes.input,
        }}
      />
    </>
  );
};

export default FormInputAmountComponent;
