import { useState } from 'react';
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import FormInputDateComponent from '@components/atoms/form-input/form-input-date/form-input-date.component';
import FormAutoCompleteComponent from '@components/atoms/form-input/form-autocomplete/form-autocomplete.component';
import InputFastField from '@components/InputFastField';
import SelectField from '@components/SelectField';
import * as Yup from 'yup';
import DeleteIcon from '@material-ui/icons/Delete';
import mandalamScreenService from '@screens/mandalam/mandalam.component.service';
import { BASE_URL } from '@constants/api.constants';
import { formOneMemberType } from '@utils/helpers';
import {
  dateStringToObject,
  getAvailableDate,
  getTodayDateObject,
} from '../../../../utils/helpers';
import { useEffect } from 'react';
import FormInputRadioComponent from '../../../atoms/form-input/form-input-radio/form-input-radio.component';
import FormInputCheckBoxComponent from '../../../atoms/form-input/form-input-checkbox/form-input-checkbox2.component';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    padding: theme.spacing(2, 2.5),
  },
  mt20: {
    marginTop: '10px',
    '& .MuiButton-root:hover': {
      backgroundColor: '#fd3550',
    },
  },
  button: {
    minWidth: '33px',
    backgroundColor: '#e80f2c',
    color: '#fff',
  },
  addButton: {
    fontSize: 13,
    padding: 4,
    fontWeight: 'bold',
  },
  emptyLine: {
    border: '1px solid #eee',
    marginBottom: 20,
  },
  checkbox: {
    margin: 10,
    fontSize: 16,
    width: '100%',
  },
}));

function initialValues() {
  const devotees = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    city: '',
    state: '',
    zipCode: '',
    devotees: [
      {
        full_name: '',
        adult_or_kid: '',
        irumudi_date: dateStringToObject('07/16/2022'),
        going_type: 'regular',
      },
    ],
  };
  return devotees;
}

export default function TypeThreeFormComponent({ event, eventId, amount }) {
  const classes = useStyles();
  // const irumudiDate = getTodayDateObject();
  const [isLoading, setIsLoading] = useState(false);
  const metaData = event.meta_data ? JSON.parse(event.meta_data) : null;
  // const irumudiAllowedDates = ['07/16/2022', '08/20/2022'];
  const [initialValuesState, setInitialValuesState] = useState(initialValues());
  const irumudiDate = getAvailableDate(metaData, 'irumudi');

  console.log({ metaData, irumudiDate });

  const irumudiRestrictedDates = metaData.irumudi_restricted_dates
    ? metaData.irumudi_restricted_dates.split(',')
    : null;
  const irumudiAllowedDays = metaData.irumudi_enabled_days_by_week
    ? metaData.irumudi_enabled_days_by_week.split(',')
    : null;
  const irumudiAllowedDates = metaData.irumudi_available_dates
    ? metaData.irumudi_available_dates.split(',')
    : null;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required'),
    mobile: Yup.string().required('Mobile is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string().required('Zipcode is required'),
    devotees: Yup.array()
      .of(
        Yup.object().shape({
          full_name: Yup.string().required('Full Name is required'),
          adult_or_kid: Yup.string().required('Select Adult or Kid'),
          irumudi_date: Yup.date().required('Select irumudi date'),
        }),
      )
      .required('Must have atleast one'),
  });

  const submitDetails = async (values) => {
    setIsLoading(true);
    const payload = {
      customer_name: values.firstName,
      customer_email: values.email,
      customer_mobile: values.mobile,
      customer_city: values.city,
      customer_state: values.state,
      customer_zipcode: values.zipCode,
      event_id: eventId,
      event_details: JSON.stringify(values.devotees),
    };
    const response = await mandalamScreenService.saveEventDetails(payload);
    if (response.status) {
      setIsLoading(true);
      const paypalUrl = BASE_URL.replace('api/', '');
      window.location.href = paypalUrl + 'mandalam-paypal?id=' + response.data.order.id;
    } else {
      setIsLoading(false);
      alert('Something went wrong, please try after sometime.');
    }
  };

  useEffect(() => {
    let updatedDevotees = [...initialValuesState.devotees];
    console.log({ irumudiDate });
    updatedDevotees[0].irumudi_date = irumudiDate || getTodayDateObject();
    console.log({ updatedDevotees });
    setInitialValuesState({ ...initialValuesState, devotees: updatedDevotees });
  }, []);

  return (
    <div className={classes.root}>
      <Box flex={1}>
        <Grid container spacing={3}>
          <Grid item sm={12} xs={12}>
            <Paper className={classes.gridRoot} elevation={0}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Typography variant="h6">Devotee Details </Typography>
                  </Grid>
                  <Grid item sm={6} align="end"></Grid>
                </Grid>
              </Box>
              <Box my={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Formik
                      validateOnChange={false}
                      validateOnBlur={false}
                      initialValues={{
                        devotees: initialValuesState.devotees,
                      }}
                      enableReinitialize
                      onSubmit={submitDetails}
                      validationSchema={validationSchema}
                    >
                      {({ errors, setFieldValue, values, handleChange }) => {
                        return (
                          <Form noValidate>
                            <Box mb={3}>
                              <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.firstName ? errors.firstName : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.lastName ? errors.lastName : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.email ? errors.email : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="mobile"
                                    name="mobile"
                                    label="Phone Number"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.mobile ? errors.mobile : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="city"
                                    name="city"
                                    label="City"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.city ? errors.city : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  {/* <SelectField
                                    id="state"
                                    name="state"
                                    options={states}
                                    label="Select State"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.state ? errors.state : ''}
                                  /> */}
                                  <FormAutoCompleteComponent
                                    id="custom-autocomplete"
                                    label="States"
                                    name="state"
                                    setFieldValue={setFieldValue}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="zipCode"
                                    name="zipCode"
                                    label="Zip Code"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.zipCode ? errors.zipCode : ''}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <div className={classes.emptyLine}></div>
                            <FieldArray
                              name="devotees"
                              render={({ remove, push }) => (
                                <div>
                                  <Grid item sm={3} xs={12}>
                                    <Typography
                                      variant="h6"
                                      style={{
                                        display: 'inline-flex',
                                        marginRight: '1em',
                                      }}
                                    >
                                      Devotee
                                    </Typography>
                                  </Grid>
                                  {/* <FormLabel /> */}
                                  <Box my={2}>
                                    {values.devotees.map((devotee, index) => (
                                      <Grid
                                        container
                                        key={index}
                                        spacing={2}
                                        alignItems="flex-start"
                                      >
                                        <Grid item xs={12} sm={3}>
                                          <InputFastField
                                            id={`devotees[${index}].full_name`}
                                            name={`devotees[${index}].full_name`}
                                            label="Full Name"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            error={
                                              errors &&
                                              errors.devotees &&
                                              errors.devotees[index].full_name
                                                ? errors.devotees[index].full_name
                                                : ''
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <SelectField
                                            id={`devotees[${index}].adult_or_kid`}
                                            name={`devotees[${index}].adult_or_kid`}
                                            options={formOneMemberType}
                                            label="Member Type"
                                            fullWidth
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item sm={3} xs={12}>
                                          <FormInputDateComponent
                                            label="Date"
                                            id={`devotees[${index}].irumudi_date`}
                                            name={`devotees[${index}].irumudi_date`}
                                            setFieldValue={setFieldValue}
                                            value={irumudiDate}
                                            minDate={metaData?.irumudi_min_date || null}
                                            maxDate={metaData?.irumudi_max_date || null}
                                            allowedDays={irumudiAllowedDays}
                                            restrictedDates={irumudiRestrictedDates}
                                            allowedDates={irumudiAllowedDates}
                                            // threeDays
                                            // readonly
                                          />
                                        </Grid>
                                        <Grid item sm={3} xs={12} className={classes.mt20}>
                                          <Button
                                            className={classes.button}
                                            variant="outlined"
                                            size="small"
                                            color="primary"
                                            onClick={() => remove(index)}
                                            disableTouchRipple
                                            focusRipple
                                          >
                                            <DeleteIcon />
                                          </Button>
                                        </Grid>
                                        <Box className={classes.checkbox}>
                                          <Grid container spacing={3} style={{ marginBottom: 25 }}>
                                            {/* <Grid item sm={3} xs={12} style={{ padding: 0 }}>
                                              <span
                                                style={{ marginLeft: 20 }}
                                                onClick={() =>
                                                  setFieldValue(
                                                    `devotees[${index}].going_type`,
                                                    'regular',
                                                  )
                                                }
                                              >
                                                <FormInputRadioComponent
                                                  id={`devotees[${index}].going_type`}
                                                  name={`devotees[${index}].going_type`}
                                                  value={'regular'}
                                                  checked={
                                                    values.devotees[index].going_type === 'regular'
                                                      ? true
                                                      : false
                                                  }
                                                  label="Regular"
                                                />
                                              </span>
                                            </Grid> */}
                                            <Grid item sm={4} xs={12} style={{ padding: 0 }}>
                                              <div style={{ paddingLeft: 10 }}>
                                                <FormInputCheckBoxComponent
                                                  id={`devotees[${index}].kanni_swamy`}
                                                  name={`devotees[${index}].kanni_swamy`}
                                                  label={'Kanni Swamy (first time)'}
                                                  value={values.devotees[index].kanni_swamy}
                                                  onChange={(value) => {
                                                    setFieldValue(
                                                      `devotees[${index}].kanni_swamy`,
                                                      value,
                                                    );

                                                    setFieldValue(
                                                      `devotees[${index}].manikandan`,
                                                      false,
                                                    );

                                                    setFieldValue(
                                                      `devotees[${index}].going_type`,
                                                      'kanni_swammy',
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </Grid>

                                            <Grid item sm={4} xs={12} style={{ padding: 0 }}>
                                              <div style={{ paddingLeft: 10 }}>
                                                <FormInputCheckBoxComponent
                                                  id={`devotees[${index}].manikandan`}
                                                  name={`devotees[${index}].manikandan`}
                                                  label={'Manikandan (third time)'}
                                                  value={values.devotees[index].manikandan}
                                                  onChange={(value) => {
                                                    setFieldValue(
                                                      `devotees[${index}].manikandan`,
                                                      value,
                                                    );

                                                    setFieldValue(
                                                      `devotees[${index}].kanni_swamy`,
                                                      false,
                                                    );

                                                    setFieldValue(
                                                      `devotees[${index}].going_type`,
                                                      'manikandan',
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Grid>
                                    ))}
                                  </Box>
                                  <Box>
                                    <Button
                                      className={classes.addButton}
                                      type="button"
                                      color="primary"
                                      variant="outlined"
                                      onClick={() =>
                                        push({
                                          full_name: '',
                                          adult_or_kid: '',
                                          irumudi_date: irumudiDate,
                                        })
                                      }
                                      disableTouchRipple
                                    >
                                      Add +
                                    </Button>
                                  </Box>
                                  <div
                                    style={{ marginBottom: 10, fontSize: 16, fontWeight: 'bold' }}
                                  >
                                    Total : ${values.devotees.length * amount}
                                  </div>
                                </div>
                              )}
                            />
                            <Box display="flex" justifyContent="center">
                              <Grid item xs={12} sm={6}>
                                <Button
                                  type="submit"
                                  color="primary"
                                  variant="contained"
                                  style={{ fontSize: 14 }}
                                  disableTouchRipple
                                  disableElevation
                                  fullWidth
                                  disabled={isLoading}
                                >
                                  Register & Pay
                                </Button>
                              </Grid>
                            </Box>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
