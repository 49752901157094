import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Footer from '@organisms/footer/footer.component';
import BookingForm from './booking-form.component';
import AppBarComponent from '@organisms/appbar/appbar.component';
import generalScreenService from './general.component.service';
import LoadingComponent from '@organisms/loading/loading.component';
import LogoComponent from '@components/atoms/logo/logo.component';
import generalStyles from './general.component.style';
import { getStorageData, setStorageData } from '@utils/storage';
import { isBrowserValid } from '@utils/helpers';
import AlertComponent from '@components/atoms/alert/alert.component';
import MenuListComponent from '@organisms/menu-list/menu-list.component';

const GeneralComponent = ({ location }) => {
  const classes = generalStyles();
  const cartItems = getStorageData('cart_items');
  const [loading, setLoading] = useState(true);
  const { isMobile } = isBrowserValid();
  const { category } = useParams();
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState(cartItems || []);
  const [categoryName, setCategoryName] = useState('');
  const [categoryData, setCategoryData] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [menuList, setMenuList] = useState([]);
  const [showOnamSadya, setShowOnamSadya] = useState({});
  const [showVolunteer, setShowVolunteer] = useState({});
  const [showContest, setShowContest] = useState({});
  const [showCanteen, setShowCanteen] = useState({});
  const slug = category || null;

  const onLoad = async () => {
    const response = await generalScreenService.getGeneralScreenProducts(slug);
    if (response.status) {
      setProducts(response.products);
      setMenuList(response.menuList);
      setCategoryName(response.category.name);
      setCategoryData(response.category);
      setShowOnamSadya(response?.onam_sadya);
      setShowVolunteer(response?.volunteer);
      setShowContest(response?.contest);
      setShowCanteen(response?.canteen);
      localStorage.setItem('menuList', JSON.stringify(menuList));
    }
    setLoading(false);
  };
  useEffect(() => {
    console.log({ slug, category });
    setLoading(true);
    onLoad();
  }, [category]);

  const updateProducts = (products) => {
    setProducts(products);
  };

  const addToCart = (product) => {
    const alreadyAdded = cart.find((item, index) => item.id === product.id);
    if (alreadyAdded) {
      cart.find((item, index) => item.id === product.id).count = product.count;
      setCart(cart);
      setStorageData('cart_items', cart);
      setAlertMessage('Requested service count updated');
      setAlert(true);
    } else {
      let updatedCart = [...cart, product];
      setCart(updatedCart);
      setStorageData('cart_items', updatedCart);
      setAlertMessage('Requested service added to cart');
      setAlert(true);
    }
  };

  return (
    <div className="ugf-covid covid-bg">
      <div className="ugf-wraper">
        <AppBarComponent
          menuList={menuList}
          cart={cart}
          selectedCategory={category}
          showOnamSadya={showOnamSadya}
          showVolunteer={showVolunteer}
          showContest={showContest}
          showCanteen={showCanteen}
        />
        <div className={`content-block ${isMobile ? classes.mobileContentBlock : ''}`}>
          <div className={classes.logoContainer}>
            <div className="logo" />
            <div className={`main-content ${isMobile ? classes.mobileMainContent : ''}`}>
              <LogoComponent />
            </div>
          </div>
          {!isMobile && (
            <MenuListComponent
              slug={slug}
              menuList={menuList}
              showVolunteer={showVolunteer}
              showContest={showContest}
              showOnamSadya={showOnamSadya}
              showCanteen={showCanteen}
            />
          )}
        </div>
        <Container>
          <div className="row">
            <div className="col-lg-10 offset-lg-7">
              <div className="form-steps active">
                <div className={`form-block ${isMobile ? classes.mobileFormBlock : ''}`}>
                  <div className="donation-header">
                    <h2>{categoryName || 'Pooja Services'}</h2>
                  </div>
                  <div>
                    {categoryData.description && (
                      <p style={{ marginTop: -22, fontSize: 15 }}> {categoryData.description} </p>
                    )}
                  </div>
                  {loading ? (
                    <LoadingComponent loading={loading} />
                  ) : (
                    <BookingForm
                      categoryData={categoryData}
                      products={products}
                      setProducts={updateProducts}
                      addToCart={addToCart}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Footer />
      </div>
      {alert && (
        <AlertComponent isAlertOpened={alert} setAlertOpen={setAlert} message={alertMessage} />
      )}
    </div>
  );
};

export default GeneralComponent;
