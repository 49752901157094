import React from 'react';
import { Box, FormControl, InputLabel, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    color: '#000 !important',
    fontSize: 14,
  },
}));

const FormInputSelectComponent = ({ label, value, name, options, onChange }) => {
  const classes = useStyles();

  return (
    <Box>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
        <Select
          native
          value={value}
          label={label}
          onChange={onChange}
          inputProps={{
            name: name,
            id: 'outlined-age-native-simple',
          }}
          inputlabelprops={{
            className: classes.input,
          }}
        >
          {options &&
            options.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FormInputSelectComponent;
