import { makeStyles } from '@material-ui/core';
import { colors } from '@styles/theme';

const useStyles = makeStyles((theme) => ({
  paper: {
    flex: 1,
    padding: 14,
    marginTop: 20,
    borderRadius: 10,
    backgroundColor: 'rgb(249, 239, 224)',
    color: 'rgb(35, 10, 16)',
    '&.MuiAccordion-rounded': {
      '&:first-child': {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
      '&:last-child': {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      },
      '&::before': {
        display: 'none',
      },
    },
  },
  tableHeading: {
    color: colors.grey30,
  },
  tableHeadingSection: {
    paddingTop: 30,
  },
  tableHeadingMobileSection: {
    paddingTop: 15,
  },
  tableHeadingMobileSection1: {
    marginTop: 0,
  },
  tableRowItem: {
    fontSize: 23,
  },
  rowMobileItem: {
    fontSize: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  priceTotal: {
    margin: 15,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    position: 'relative',
    bottom: '8px',
  },
  buttonWidth40: {
    width: '40%',
  },
  totalPrice: {
    fontSize: 22,
    fontWeight: 700,
    color: colors.primaryOrange,
  },
  totalPriceMobile: {
    marginTop: 15,
    fontSize: 17,
    fontWeight: 700,
    color: colors.primaryOrange,
  },
  priceMobile: {
    marginTop: 18,
    fontWeight: 700,
  },
  desc: {
    color: colors.grey10,
  },
  font600: {
    fontWeight: 600,
  },
}));
export default useStyles;
