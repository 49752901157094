import { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import FormInputDateComponent from '@components/atoms/form-input/form-input-date/form-input-date.component';
import InputFastField from '@components/InputFastField';
import SelectField from '@components/SelectField';
import * as Yup from 'yup';
import DeleteIcon from '@material-ui/icons/Delete';
import mandalamScreenService from '@screens/mandalam/mandalam.component.service';
import { states } from '@constants/state';
import { BASE_URL } from '@constants/api.constants';
import { adultKid } from '@utils/helpers';
import { getAvailableDate, getTodayDateObject } from '../../../../utils/helpers';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    padding: theme.spacing(2, 2.5),
  },
  mt20: {
    marginTop: '10px',
    '& .MuiButton-root:hover': {
      backgroundColor: '#fd3550',
    },
  },
  button: {
    minWidth: '33px',
    backgroundColor: '#e80f2c',
    color: '#fff',
  },
  poojaDate: {
    marginTop: '1.5em',
  },
  addButton: {
    fontSize: 13,
    padding: 4,
    fontWeight: 'bold',
  },
  emptyLine: {
    border: '1px solid #eee',
    marginBottom: 20,
  },
}));

function initialValues(eventDate) {
  const devotees = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    city: '',
    state: '',
    zipCode: '',
    devotees: [
      {
        full_name: '',
        steps_date: dayjs(eventDate),
        adult_or_kid: '',
      },
    ],
  };
  return devotees;
}

export default function TypeSixFormComponent({ eventId, amount, eventDetails }) {
  const metaData = eventDetails.meta_data ? JSON.parse(eventDetails.meta_data) : null;
  const eventDate = getAvailableDate(metaData, 'maladharanam');
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  console.log({ metaData, eventDetails, eventDate });
  const eventAllowedDays = metaData?.enabled_days_by_week
    ? metaData.enabled_days_by_week.split(',')
    : null;

  const eventRestrictedDates = metaData?.restricted_dates
    ? metaData.restricted_dates.split(',')
    : null;

  const eventAllowedDates = metaData?.available_dates ? metaData.available_dates.split(',') : null;
  const [isDateEditable, setIsDateEditable] = useState(true);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required'),
    mobile: Yup.string().required('Mobile is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string().required('Zipcode is required'),
    devotees: Yup.array()
      .of(
        Yup.object().shape({
          full_name: Yup.string().required('Name is required'),
          steps_date: Yup.string().required('Date is required'),
          adult_or_kid: Yup.string().required('Select Adult or Kid'),
        }),
      )
      .required('Must have atleast one'),
  });

  const submitDetails = async (values) => {
    setIsLoading(true);
    const payload = {
      customer_name: values.firstName,
      customer_email: values.email,
      customer_mobile: values.mobile,
      customer_city: values.city,
      customer_state: values.state,
      customer_zipcode: values.zipCode,
      event_id: eventId,
      event_details: JSON.stringify(values.devotees),
    };
    const response = await mandalamScreenService.saveEventDetails(payload);
    if (response.status) {
      setIsLoading(true);
      let paypalUrl = BASE_URL.replace('api/', '');
      paypalUrl = paypalUrl + 'mandalam-paypal?id=' + response.data.order.id;
      window.location.href = paypalUrl;
    } else {
      setIsLoading(false);
      alert('Something went wrong, please try after sometime.');
    }
  };
  useEffect(() => {
    if (eventDetails?.editable_event_date === 1) {
      setIsDateEditable(false);
    }
  }, []);
  return (
    <div className={classes.root}>
      <Box flex={1}>
        <Grid container spacing={3}>
          <Grid item sm={12} xs={12}>
            <Paper className={classes.gridRoot} elevation={0}>
              <Typography variant="h6"> Devotee Details </Typography>
              <Box my={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Formik
                      validateOnChange={false}
                      validateOnBlur={false}
                      initialValues={{
                        devotees: initialValues(eventDate).devotees,
                      }}
                      onSubmit={submitDetails}
                      validationSchema={validationSchema}
                    >
                      {({ errors, setFieldValue, values, handleChange }) => {
                        return (
                          <Form noValidate>
                            <Box mb={3}>
                              <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    error={errors.firstName ? errors.firstName : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.lastName ? errors.lastName : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.email ? errors.email : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="mobile"
                                    name="mobile"
                                    label="Phone Number"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.mobile ? errors.mobile : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="city"
                                    name="city"
                                    label="City"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.city ? errors.city : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <SelectField
                                    id="state"
                                    name="state"
                                    options={states}
                                    label="Select State"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.state ? errors.state : ''}
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputFastField
                                    id="zipCode"
                                    name="zipCode"
                                    label="Zip Code"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                    error={errors.zipCode ? errors.zipCode : ''}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <div className={classes.emptyLine}></div>
                            <FieldArray
                              name="devotees"
                              render={({ remove, push }) => (
                                <div>
                                  <Grid item sm={3} xs={12}>
                                    <Typography
                                      variant="h6"
                                      style={{
                                        display: 'inline-flex',
                                        marginRight: '1em',
                                      }}
                                    >
                                      Devotee
                                    </Typography>
                                  </Grid>
                                  <Box my={2}>
                                    {values.devotees.map((devotee, index) => (
                                      <Grid
                                        container
                                        key={index}
                                        spacing={2}
                                        alignItems="flex-start"
                                      >
                                        <Grid item xs={12} sm={3}>
                                          <InputFastField
                                            id={`devotees[${index}].full_name`}
                                            name={`devotees[${index}].full_name`}
                                            label="Name"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            error={
                                              errors &&
                                              errors.devotees &&
                                              errors.devotees[index].full_name
                                                ? errors.devotees[index].full_name
                                                : ''
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <SelectField
                                            id={`devotees[${index}].adult_or_kid`}
                                            name={`devotees[${index}].adult_or_kid`}
                                            options={adultKid}
                                            label="Member Type"
                                            fullWidth
                                            variant="outlined"
                                          />
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                          <FormInputDateComponent
                                            disabled={isDateEditable}
                                            label="Date"
                                            id={`devotees[${index}].steps_date`}
                                            name={`devotees[${index}].steps_date`}
                                            setFieldValue={setFieldValue}
                                            onlySaturday
                                            minDate={metaData?.mandalam_min_date || undefined}
                                            maxDate={metaData?.mandalam_max_date || undefined}
                                            allowedDays={eventAllowedDays}
                                            restrictedDates={eventRestrictedDates}
                                            allowedDates={eventAllowedDates}
                                            value={
                                              eventDetails.event_date
                                                ? dayjs(eventDetails.event_date)
                                                : eventDate
                                            }
                                          />
                                        </Grid>
                                        <Grid item sm={3} xs={12} className={classes.mt20}>
                                          <Button
                                            className={classes.button}
                                            variant="outlined"
                                            size="small"
                                            color="secondary"
                                            onClick={() => remove(index)}
                                            disableTouchRipple
                                            focusRipple
                                          >
                                            <DeleteIcon />
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Box>
                                  <Box my={2}>
                                    <Button
                                      className={classes.addButton}
                                      type="button"
                                      color="primary"
                                      variant="outlined"
                                      onClick={() =>
                                        push({
                                          full_name: '',
                                          steps_date: getTodayDateObject(),
                                          adult_or_kid: '',
                                        })
                                      }
                                      disableTouchRipple
                                    >
                                      Add +
                                    </Button>
                                  </Box>
                                  <div
                                    style={{ marginBottom: 10, fontSize: 16, fontWeight: 'bold' }}
                                  >
                                    Total : ${values.devotees.length * amount}
                                  </div>
                                </div>
                              )}
                            />
                            <Box display="flex" justifyContent="center">
                              <Grid item xs={12} sm={6}>
                                <Button
                                  type="submit"
                                  color="primary"
                                  variant="contained"
                                  style={{ fontSize: 14 }}
                                  disableTouchRipple
                                  disableElevation
                                  fullWidth
                                  disabled={isLoading}
                                >
                                  Register & Pay
                                </Button>
                              </Grid>
                            </Box>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
