import moment from 'moment-timezone';
import * as Yup from 'yup';

/*
 * Detect browser agent
 *
 * @return {boolean}
 * */
export const isBrowserValid = () => {
  const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1,
    isChrome = navigator.userAgent.indexOf('Chrome') !== -1,
    // Edge (based on chromium) detection
    isEdgeChromium = navigator.userAgent.indexOf('Edg') !== -1,
    isMobile = navigator.userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i,
    );

  return {
    isBrowser: isEdgeChromium || isFirefox || isChrome,
    isMobile,
    isFirefox,
    isChrome,
  };
};

/*
 * replace - with " "
 *
 * @return String
 * */
export const processName = (name) => {
  name = name.replace(' ', '-');
  name = name.toLowerCase();
  return name;
};

/*
 * convert to slug
 *
 * @return slug url
 * */
export const parseSlug = (slugName) => {
  if (!slugName) return 'Pooja Services';
  slugName = slugName.replace('-', ' ');
  return slugName
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/*
 * optionsList - array
 * idKey - string
 * valueKey - string
 * @return array options
 * */
export const formatOptions = (optionsList, idKey, nameKey, valueKey = 'nachathiram_dates') => {
  let options = [];
  if (optionsList) {
    optionsList.forEach((data) => {
      options.push({
        id: data[nameKey],
        name: data[nameKey],
        value: data[valueKey],
      });
    });
  }
  return options;
};

export const formatNachathiramDate = (data, value) => {
  let options = [];
  if (data) {
    const date = data.find((item) => item.name === value);
    if (date) {
      const filterDate = date.value.split(',');
      filterDate.forEach((item, i) => {
        options.push({
          id: item,
          name: item,
        });
      });
      return options;
    }
  }
  return options;
};

/*
 * id - String
 * name - string
 * @export array
 * */
export const adultKid = [
  {
    id: 1,
    name: 'Adult',
  },
  {
    id: 2,
    name: 'Kid',
  },
];

export const formOneMemberType = [
  {
    id: 1,
    name: 'Adult',
  },
  {
    id: 2,
    name: 'Kid',
  },
  {
    id: 3,
    name: 'Senior Citizen',
  },
];

/*
 * id - String
 * key - string
 * name - string
 * @export array
 * */

const padiOneand18 = 5516;
const otherPadis = 1516;
export const padiList = [
  {
    id: '1-' + padiOneand18,
    key: '1',
    name: '1',
  },
  {
    id: '2-' + otherPadis,
    key: '2',
    name: '2',
  },
  {
    id: '3-' + otherPadis,
    key: '3',
    name: '3',
  },
  {
    id: '4-' + otherPadis,
    key: '4',
    name: '4',
  },
  {
    id: '5-' + otherPadis,
    key: '5',
    name: '5',
  },
  {
    id: '6-' + otherPadis,
    name: '6',
  },
  {
    id: '7-' + otherPadis,
    name: '7',
  },
  {
    id: '8-' + otherPadis,
    name: '8',
  },
  {
    id: '9-' + otherPadis,
    name: '9',
  },
  {
    id: '10-' + otherPadis,
    name: '10',
  },
  {
    id: '11-' + otherPadis,
    name: '11',
  },
  {
    id: '12-' + otherPadis,
    name: '12',
  },
  {
    id: '13-' + otherPadis,
    name: '13',
  },
  {
    id: '14-' + otherPadis,
    name: '14',
  },
  {
    id: '15-' + otherPadis,
    name: '15',
  },
  {
    id: '16-' + otherPadis,
    name: '16',
  },
  {
    id: '17-' + otherPadis,
    name: '17',
  },
  {
    id: '18-' + padiOneand18,
    name: '18',
  },
];

export const formatDate = (date, memberType, memberTypeScheduling) => {
  console.log({ date, memberType });
  if (date) if (!memberTypeScheduling) return new Date(date);
  if (date && memberType) {
    const adultOffset = memberTypeScheduling?.adult ? parseInt(memberTypeScheduling.adult) : 8;
    const kidOffset = memberTypeScheduling?.kid ? parseInt(memberTypeScheduling.kid) : 4;
    const seniorCitizenOffset = memberTypeScheduling?.senior_citizen
      ? parseInt(memberTypeScheduling.senior_citizen)
      : 1;
    let dateFormat;

    if (memberType === 1) {
      const newDate = new Date(date);
      const addEightDay = newDate.setDate(newDate.getDate() + adultOffset);
      dateFormat = new Date(addEightDay);
    } else if (memberType === 2) {
      const newDate = new Date(date);
      const addFourDay = newDate.setDate(newDate.getDate() + kidOffset);
      dateFormat = new Date(addFourDay);
    } else if (memberType === 3) {
      const newDate = new Date(date);
      const addOneDay = newDate.setDate(newDate.getDate() + seniorCitizenOffset);
      dateFormat = new Date(addOneDay);
    }
    return dateFormat;
  }
};

export const dateStringToObject = (dateStr) => {
  // return moment.utc(dateStr).subtract(9.5, 'hours');
  return moment(dateStr);

  // return moment.utc(dateStr).tz('America/New_York');
  return moment.tz(dateStr, 'MM/DD/yyyy', 'UTC');
  // return moment.tz(dateStr, 'America/Los_Angeles').subtract(9.5, 'hours');
  return moment.utc(dateStr);
};

export const getTodayDateObject = () => {
  return moment.tz('America/New_York');
};

export const getAvailableDate = (metaData, type) => {
  console.log({ metaData });
  if (type === 'irumudi') {
    var minDate = new Date(metaData?.irumudi_min_date);
    var maxDate = new Date(metaData?.irumudi_max_date);

    var enabledDays = metaData?.irumudi_enabled_days_by_week
      ? metaData.irumudi_enabled_days_by_week.split(',')
      : [];
    var restrictedDates = metaData?.irumudi_restricted_dates
      ? metaData.irumudi_restricted_dates.split(',')
      : [];

    var availableDates = metaData?.irumudi_available_dates
      ? metaData.irumudi_available_dates.split(',')
      : [];
  } else {
    var minDate = new Date(metaData?.mandalam_min_date);
    var maxDate = new Date(metaData?.mandalam_max_date);

    var enabledDays = metaData?.enabled_days_by_week
      ? metaData.enabled_days_by_week.split(',')
      : [];
    var restrictedDates = metaData?.restricted_dates ? metaData.restricted_dates.split(',') : [];

    var availableDates = metaData?.available_dates ? metaData.available_dates.split(',') : [];
  }

  minDate = moment(minDate);
  maxDate = moment(maxDate);

  let status = true;
  let sno = 0;
  while (status) {
    if (minDate.format('YYYY-MM-DD') <= maxDate.format('YYYY-MM-DD')) {
      let greaterThanToday = false;
      if (minDate.format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) {
        greaterThanToday = true;
      }

      if (greaterThanToday === true) {
        const findDay = enabledDays.find((day) => day === minDate.format('dddd'));

        const findInRestrictedDate = restrictedDates.find(
          (rDate) => rDate === minDate.format('MM/DD/yyyy'),
        );

        const findInAvailableDate = availableDates.find(
          (aDate) => aDate === minDate.format('MM/DD/yyyy'),
        );

        if (
          (findDay &&
            !findInRestrictedDate &&
            enabledDays.length > 0 &&
            availableDates.length === 0) ||
          (!findInRestrictedDate && enabledDays.length === 0) ||
          (findDay && enabledDays.length > 0 && findInAvailableDate && availableDates.length > 0) ||
          (findInAvailableDate && availableDates.length > 0 && enabledDays.length === 0)
        ) {
          console.log({
            findDay,
            currentDate: minDate.format('MM/DD/yyyy'),
            type,
            availableDates,
            enabledDays,
            restrictedDates,
            condition1:
              findDay && enabledDays.length > 0 && findInAvailableDate && availableDates.length > 0,
            condition2:
              findInAvailableDate && availableDates.length > 0 && enabledDays.length === 0,
            condition3: !findInRestrictedDate && enabledDays.length === 0,
            condition4:
              findDay &&
              !findInRestrictedDate &&
              enabledDays.length > 0 &&
              availableDates.length === 0,
          });
          return minDate.format('MM/DD/yyyy');
        }
      }
      // console.log('valid date', {
      //   currentDate: minDate.format('MM/DD/yyyy'),
      //   greaterThanToday,
      //   type,
      // });
      minDate = moment(minDate, 'DD-MM-YYYY').add(1, 'days');
    } else {
      // console.log('invalid date', { currentDate: minDate.format('MM/DD/yyyy') });
      status = false;
    }

    if (sno >= 100) {
      status = false;
    }

    sno++;
  }
};

export const formatActivityResponse = (data) => {
  if (data) {
    const copyData = { ...data };
    const key = Object.keys(copyData);
    const formatedData = key.map((item) => {
      return {
        [item]: data[item].map((res) => ({
          ...res,
          isChecked: false,
        })),
      };
    });
    console.log(Object.assign({}, ...formatedData));
    return Object.assign({}, ...formatedData);
  }
  return {};
};

export const getCanteenProductCost = (values, name) => {
  let count = 0;
  if (values.category_product) {
    const catProducts = values.category_product[name];
    for (let i = 0; i < catProducts.length; i++) {
      if (catProducts[i]?.count) {
        count += parseInt(catProducts[i].count) * parseInt(catProducts[i].price);
      }
    }
    return count;
  }
  return 0;
};

export const totalCost = (a, b) => {
  return a + b;
};

export const malaIrumudiValidation = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().required('Email is required'),
  mobile: Yup.string().required('Mobile is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipCode: Yup.string().required('Zipcode is required'),
  whatsappNumber: Yup.string().required('Whatsapp number is required'),
  devotees: Yup.array()
    .of(
      Yup.object().shape({
        full_name: Yup.string().required('Full Name is required'),
        adult_or_kid: Yup.string().required('Select Adult or Kid'),
        maladharnam_date: Yup.date().required('Select maladharanam date'),
        irumudi_date: Yup.date().required('Select irumudi date'),
      }),
    )
    .required('Must have atleast one'),
});

export const malaIrumudiUpdateValidation = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().required('Email is required'),
  mobile: Yup.string().required('Mobile is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipCode: Yup.string().required('Zipcode is required'),
  devotees: Yup.array()
    .of(
      Yup.object().shape({
        full_name: Yup.string().required('Full Name is required'),
        adult_or_kid: Yup.string().required('Select Adult or Kid'),
        maladharnam_date: Yup.date().required('Select maladharanam date'),
        irumudi_date: Yup.date().required('Select irumudi date'),
      }),
    )
    .required('Must have atleast one'),
});
