export function setStorageData(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
  return true;
}

export function getStorageData(key) {
  const storageData = localStorage.getItem(key);
  if (storageData) return JSON.parse(storageData);
  return null;
}
