import './App.css';

import { HashRouter, Switch, Route } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@material-ui/core';
import { colors } from '@styles/theme';
import BookingStatusComponent from '@screens/booking-status/booking-status.component';
import PageNotFoundComponent from '@screens/page-not-found/page-not-found.component';
import PaypalComponent from '@organisms/paypal/paypal.component';
import Complete from '@screens/complete.component';
import General from '@screens/general/general.component';
import CartPage from '@screens/cart/index.component';
import CheckoutComponent from '@screens/checkout/checkout.component';
import MandalamComponent from './screens/mandalam/mandalam.component';
import FormTestComponent from './screens/form-test/form-test.component';
import LifeTimeMembershipComponent from '@screens/life-time-membership/life-time-membership.component';
import SponsorshipComponent from '@screens/padi-sponsorship/padi-sponsorship.component';
import VolunteerRegisterationComponent from './screens/volunteer-registration/volunteer-registration.component';
import OnamSadyaComponent from './screens/onam-sadya/onam-sadya.component';
import ContestRegistrationComponent from './screens/contest-registration/contest-registration.component';
import CanteenComponent from './screens/canteen/canteen.component';

const theme = createTheme({
  multilineColor: {
    color: '#fff',
  },
  palette: {
    primary: {
      main: colors.primaryOrange,
    },
    secondary: {
      main: colors.secondaryOrange,
    },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    h5: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '33px',
      letterSpacing: '0px',
      color: colors.darkBrown,
    },
    h6: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '27px',
      letterSpacing: '0.15px',
      color: colors.darkBrown,
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'rgb(255 143 0 / 28%)',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '1.2rem',
      },
    },
    MuiButton: {
      root: {
        fontFamily: '"Open Sans", sans-serif',
        letterSpacing: 0,
        textTransform: 'none',
        boxShadow: 'none',
      },
      containedPrimary: {
        fontSize: 18,
        fontWeight: 500,
        borderRadius: 26,
        padding: '8px 24px',
      },
      outlinedPrimary: {
        fontSize: 18,
        fontWeight: 500,
        borderRadius: 26,
        padding: '8px 24px',
      },
      containedSizeSmall: {
        fontSize: 16,
        fontWeight: 500,
        borderRadius: 8,
        padding: '8px 16px',
      },
      outlinedSizeSmall: {
        fontSize: 16,
        fontWeight: 500,
        borderRadius: 4,
        padding: '8px 16px',
      },
      textPrimary: {
        fontSize: '1.5rem',
        '&:hover': {
          backgroundColor: 'rgb(255 143 0 / 28%)',
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '14px',
      },
    },
    MuiTypography: {
      root: {
        fontFamily: '"Open Sans", sans-serif',
        fontWeight: 400,
        lineHeight: 1.43,
      },
      body2: {
        fontSize: '1.25rem',
      },
      body1: {
        fontSize: '1.5rem',
      },
      subtitle1: {
        fontSize: '1.5rem',
      },
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        '& .MuiPickersCalendarHeader-dayLabel': {
          fontSize: '1.25rem',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1em',
      },
    },
    MuiFormHelperText: {
      contained: {
        color: colors.danger,
        fontSize: '1.3rem',
      },
    },
    MuiAutocomplete: {
      paper: {
        fontSize: '1.3rem !important',
        padding: '0 5px !important',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.2rem !important',
      },
    },
    MuiSelect: {
      root: {
        fontSize: '1.3rem !important',
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <Switch>
          <Route exact path="/" component={General} />
          <Route exact path="/services/:category" component={General} />
          <Route exact path="/mandala-season/:eventName" component={MandalamComponent} />
          <Route exact path="/home" component={General} />
          <Route exact path="/cart" component={CartPage} />
          <Route exact path="/complete" component={Complete} />
          <Route exact path="/life-time-membership" component={LifeTimeMembershipComponent} />
          <Route exact path="/padi-sponsorship" component={SponsorshipComponent} />
          <Route exact path="/volunteer-registration" component={VolunteerRegisterationComponent} />
          <Route exact path="/contest-registration" component={ContestRegistrationComponent} />
          <Route exact path="/canteen" component={CanteenComponent} />
          <Route exact path="/onam-sadya" component={OnamSadyaComponent} />
          <Route exact path="/checkout" component={CheckoutComponent} />
          <Route exact path="/booking-status" component={BookingStatusComponent} />
          <Route exact path="/paypal" component={PaypalComponent} />
          <Route exact path="/form-test" component={FormTestComponent} />
          <Route path="*" component={PageNotFoundComponent} />
        </Switch>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
