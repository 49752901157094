import { Box } from '@material-ui/core';
import React from 'react';
import dayjs from 'dayjs';
import { colors } from '@styles/theme';
import { isMobile } from 'react-device-detect';

// const borderColor = 'rgb(229 159 96)';
const borderColor = 'black';

const CalendarViewComponent = ({ date }) => {
  const month = dayjs(date).format('MMM');
  const day = dayjs(date).format('DD');
  const weekDay = dayjs(date).format(isMobile ? 'ddd' : 'dddd');
  return (
    <Box>
      <Box
        style={{
          borderTop: `1px solid ${borderColor}`,
          borderLeft: `1px solid ${borderColor}`,
          borderRight: `1px solid ${borderColor}`,
          padding: 1,
          marginRight: 14,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          backgroundColor: colors.primaryOrange,
        }}
      >
        <div
          style={{
            textAlign: 'center',
            color: colors.white,
          }}
        >
          {month.toUpperCase()}{' '}
        </div>
      </Box>
      <Box
        style={{
          borderBottom: `1px solid ${borderColor}`,
          borderRight: `1px solid ${borderColor}`,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          padding: 1,
          marginRight: 14,
          borderLeft: `1px solid ${borderColor}`,
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontSize: 16 }}> {day} </p>
          <p style={{ fontSize: 13 }}> {weekDay.toUpperCase()} </p>
        </div>
      </Box>
    </Box>
  );
};

export default CalendarViewComponent;
