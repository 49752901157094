import { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import FormAutoCompleteComponent from '@components/atoms/form-input/form-autocomplete/form-autocomplete.component';
import FormInputAreaComponent from '@components/atoms/form-input/form-input-area/form-input-area.component';
import InputFastField from '@components/InputFastField';
import SelectField from '@components/SelectField';
import { isBrowserValid } from '@utils/helpers';
import { getStorageData } from '@utils/storage';
import * as Yup from 'yup';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { BASE_URL } from '@constants/api.constants';
import { createLifeTimeMember } from './life-time-membership.component.service';
import mandalamScreenService from '../mandalam/mandalam.component.service';
import generalScreenService from '../general/general.component.service';
import FormWrapperComponent from '@organisms/form-wrapper/form-wrapper.component';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    padding: theme.spacing(2, 2.5),
    boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
  },
  addButton: {
    fontSize: 13,
    padding: 8,
    fontWeight: 'bold',
    lineHeight: '10px',
  },
  button: {
    minWidth: '33px',
    backgroundColor: '#e80f2c',
    color: '#fff',
  },
}));

function initialValues() {
  const devotees = {
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    mobile: '',
    city: '',
    state: '',
    zipCode: '',
    gothram: '',
    devotees: [
      {
        full_name: '',
        relationship: '',
        nachathiram: '',
      },
    ],
  };
  return devotees;
}

const LifeTimeMembershipComponent = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [showOnamSadya, setShowOnamSadya] = useState({});
  const [showVolunteer, setShowVolunteer] = useState({});
  const [showContest, setShowContest] = useState({});
  // const menuList = JSON.parse(localStorage.getItem('menuList'));
  const [nachathiram, setNachathiram] = useState([]);
  const category = '';
  const [irumudiMinDate, setIrumudiMinDate] = useState(new Date());
  const { isMobile } = isBrowserValid();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    address: Yup.string().required('Address is required'),
    email: Yup.string().required('Email is required'),
    mobile: Yup.string().required('Mobile is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string().required('Zipcode is required'),
    gothram: Yup.string().required('Gothram is required'),
    devotees: Yup.array()
      .of(
        Yup.object().shape({
          full_name: Yup.string().required('Full Name is required'),
          relationship: Yup.string().required('Relationship is required'),
          nachathiram: Yup.string().required('Nachathiram is required'),
        }),
      )
      .required('Must have atleast one'),
  });

  const submitDetails = async (values) => {
    setIsLoading(true);
    const devotees = values.devotees.map((devotee) => ({ ...devotee, email: values.email }));
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobile: values.mobile,
      city: values.city,
      address: values.address,
      state: values.state,
      zipCode: values.zipCode,
      gothram: values.gothram,
      familyInfo: devotees,
    };

    console.log(payload);

    const response = await createLifeTimeMember(payload);
    if (response.status) {
      setIsLoading(true);
      const paypalUrl = BASE_URL.replace('api/', '');
      window.location.href = paypalUrl + 'lifetime-membership-paypal?id=' + response.data.order.id;
    } else {
      setIsLoading(false);
      alert(response.message);
    }
  };

  const onLoad = async () => {
    if (isMobile) {
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(async () => {
    const nachathiram = await mandalamScreenService.getNatchathiramDetails();
    if (nachathiram.status) setNachathiram(nachathiram.data);
    setIsLoading(false);
  }, []);

  return (
    <FormWrapperComponent
      title="LIFE TIME MEMBERSHIP"
      showOnamSadya={showOnamSadya}
      showContest={showContest}
      showVolunteer={showVolunteer}
      selectedCategory={category}
    >
      <div className={classes.root}>
        <Box flex={1}>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
              <Paper className={classes.gridRoot} elevation={0}>
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                  }}
                >
                  APPLICANT INFORMATION
                </div>

                <Box my={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={{
                          devotees: initialValues().devotees,
                        }}
                        onSubmit={submitDetails}
                        validationSchema={validationSchema}
                      >
                        {({ errors, setFieldValue, values, handleChange }) => {
                          return (
                            <Form noValidate>
                              <Box mb={3}>
                                <Grid container spacing={2}>
                                  <Grid item sm={6} xs={12}>
                                    <InputFastField
                                      id="firstName"
                                      name="firstName"
                                      label="First Name"
                                      variant="outlined"
                                      fullWidth
                                      onChange={handleChange}
                                      error={errors.firstName ? errors.firstName : ''}
                                    />
                                  </Grid>
                                  <Grid item sm={6} xs={12}>
                                    <InputFastField
                                      id="lastName"
                                      name="lastName"
                                      label="Last Name"
                                      variant="outlined"
                                      onChange={handleChange}
                                      fullWidth
                                      error={errors.lastName ? errors.lastName : ''}
                                    />
                                  </Grid>
                                  <Grid item sm={6} xs={12}>
                                    <InputFastField
                                      id="address"
                                      name="address"
                                      label="Address"
                                      variant="outlined"
                                      multiline={true}
                                      rows={2}
                                      onChange={handleChange}
                                      fullWidth
                                      error={errors.address ? errors.address : ''}
                                    />
                                  </Grid>
                                  <Grid item sm={6} xs={12}>
                                    <InputFastField
                                      id="email"
                                      name="email"
                                      label="Email"
                                      variant="outlined"
                                      onChange={handleChange}
                                      fullWidth
                                      error={errors.email ? errors.email : ''}
                                    />
                                  </Grid>
                                  <Grid item sm={6} xs={12}>
                                    <InputFastField
                                      id="mobile"
                                      name="mobile"
                                      label="Phone Number"
                                      variant="outlined"
                                      onChange={handleChange}
                                      fullWidth
                                      error={errors.mobile ? errors.mobile : ''}
                                    />
                                  </Grid>
                                  <Grid item sm={6} xs={12}>
                                    <InputFastField
                                      id="city"
                                      name="city"
                                      label="City"
                                      variant="outlined"
                                      onChange={handleChange}
                                      fullWidth
                                      error={errors.city ? errors.city : ''}
                                    />
                                  </Grid>
                                  <Grid item sm={6} xs={12}>
                                    <FormAutoCompleteComponent
                                      id="custom-autocomplete"
                                      label="States"
                                      name="state"
                                      setFieldValue={setFieldValue}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item sm={6} xs={12}>
                                    <InputFastField
                                      id="zipCode"
                                      name="zipCode"
                                      label="Zip Code"
                                      variant="outlined"
                                      onChange={handleChange}
                                      fullWidth
                                      error={errors.zipCode ? errors.zipCode : ''}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                              <div className={classes.emptyLine}></div>
                              <FieldArray
                                name="devotees"
                                render={({ remove, push }) => (
                                  <div>
                                    <Grid item sm={6} xs={12}>
                                      <div
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        FAMILY INFORMATION
                                      </div>
                                    </Grid>
                                    <Box my={2}>
                                      {values.devotees.map((devotee, index) => (
                                        <Grid
                                          container
                                          key={index}
                                          spacing={2}
                                          alignItems="flex-start"
                                        >
                                          <Grid item xs={12} sm={3}>
                                            <InputFastField
                                              id={`devotees[${index}].full_name`}
                                              name={`devotees[${index}].full_name`}
                                              label="Full Name"
                                              variant="outlined"
                                              onChange={handleChange}
                                              fullWidth
                                            />
                                          </Grid>
                                          <Grid item sm={3} xs={12}>
                                            <InputFastField
                                              id={`devotees[${index}].relationship`}
                                              name={`devotees[${index}].relationship`}
                                              label="Relationship"
                                              variant="outlined"
                                              onChange={handleChange}
                                              fullWidth
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                            <SelectField
                                              id={`devotees[${index}].nachathiram`}
                                              name={`devotees[${index}].nachathiram`}
                                              label="Select Nachathiram"
                                              variant="outlined"
                                              fullWidth
                                              options={nachathiram}
                                            />
                                          </Grid>
                                          <Grid item sm={2} xs={12} className={classes.mt20}>
                                            <Button
                                              className={classes.button}
                                              variant="outlined"
                                              size="small"
                                              color="primary"
                                              onClick={() => remove(index)}
                                              disableTouchRipple
                                              focusRipple
                                            >
                                              <DeleteIcon />
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      ))}
                                    </Box>
                                    <Box>
                                      <Button
                                        className={classes.addButton}
                                        type="button"
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {
                                          if (values.devotees.length < 4) {
                                            push({
                                              full_name: '',
                                              relationship: '',
                                              nachathiram_id: '',
                                            });
                                          }
                                        }}
                                        disableTouchRipple
                                      >
                                        + Add Family Member
                                      </Button>
                                    </Box>
                                    <Box mt={3}>
                                      <FormInputAreaComponent
                                        label="Gothram"
                                        name="gothram"
                                        id="gothram"
                                        onChange={handleChange}
                                        rows={5}
                                        error={errors.gothram ? errors.gothram : ''}
                                      />
                                    </Box>
                                    <div
                                      style={{
                                        marginBottom: 10,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        marginLeft: 150,
                                      }}
                                    >
                                      {({ errors, setFieldValue, values, handleChange }) => {
                                        console.log(errors);
                                        return (
                                          <Form noValidate>
                                            <Box mb={3}>
                                              <Grid container spacing={2}>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="firstName"
                                                    name="firstName"
                                                    label="First Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    error={errors.firstName ? errors.firstName : ''}
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="lastName"
                                                    name="lastName"
                                                    label="Last Name"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={errors.lastName ? errors.lastName : ''}
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="address"
                                                    name="address"
                                                    label="Address"
                                                    variant="outlined"
                                                    multiline={true}
                                                    rows={2}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={errors.address ? errors.address : ''}
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="email"
                                                    name="email"
                                                    label="Email"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={errors.email ? errors.email : ''}
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="mobile"
                                                    name="mobile"
                                                    label="Phone Number"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={errors.mobile ? errors.mobile : ''}
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="city"
                                                    name="city"
                                                    label="City"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={errors.city ? errors.city : ''}
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  <FormAutoCompleteComponent
                                                    id="custom-autocomplete"
                                                    label="States"
                                                    name="state"
                                                    setFieldValue={setFieldValue}
                                                    fullWidth
                                                  />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                  <InputFastField
                                                    id="zipCode"
                                                    name="zipCode"
                                                    label="Zip Code"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={errors.zipCode ? errors.zipCode : ''}
                                                  />
                                                </Grid>
                                              </Grid>
                                            </Box>
                                            <div className={classes.emptyLine}></div>
                                            <FieldArray
                                              name="devotees"
                                              render={({ remove, push }) => (
                                                <div>
                                                  <Grid item sm={6} xs={12}>
                                                    <div
                                                      style={{
                                                        fontSize: 16,
                                                        fontWeight: 'bold',
                                                      }}
                                                    >
                                                      FAMILY INFORMATION
                                                    </div>
                                                  </Grid>
                                                  <Box my={2}>
                                                    {values.devotees.map((devotee, index) => (
                                                      <Grid
                                                        container
                                                        key={index}
                                                        spacing={2}
                                                        alignItems="flex-start"
                                                      >
                                                        <Grid item xs={12} sm={3}>
                                                          <InputFastField
                                                            id={`devotees[${index}].full_name`}
                                                            name={`devotees[${index}].full_name`}
                                                            label="Full Name"
                                                            variant="outlined"
                                                            fullWidth
                                                            onChange={handleChange}
                                                          />
                                                        </Grid>
                                                        <Grid item sm={3} xs={12}>
                                                          <InputFastField
                                                            id={`devotees[${index}].relationship`}
                                                            name={`devotees[${index}].relationship`}
                                                            label="Relationship"
                                                            variant="outlined"
                                                            fullWidth
                                                            onChange={handleChange}
                                                          />
                                                        </Grid>
                                                        <Grid item xs={12} sm={3}>
                                                          <SelectField
                                                            id={`devotees[${index}].nachathiram`}
                                                            name={`devotees[${index}].nachathiram`}
                                                            label="Select Nachathiram"
                                                            variant="outlined"
                                                            fullWidth
                                                            options={nachathiram}
                                                          />
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          sm={2}
                                                          xs={12}
                                                          className={classes.mt20}
                                                        >
                                                          <Button
                                                            className={classes.button}
                                                            variant="outlined"
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => remove(index)}
                                                            disableTouchRipple
                                                            focusRipple
                                                          >
                                                            <DeleteIcon />
                                                          </Button>
                                                        </Grid>
                                                      </Grid>
                                                    ))}
                                                  </Box>
                                                  <Box>
                                                    <Button
                                                      className={classes.addButton}
                                                      type="button"
                                                      color="primary"
                                                      variant="outlined"
                                                      onClick={() => {
                                                        if (values.devotees.length < 4) {
                                                          push({
                                                            full_name: '',
                                                            relationship: '',
                                                            nachathiram_id: '',
                                                          });
                                                        }
                                                      }}
                                                      disableTouchRipple
                                                    >
                                                      + Add Family Member
                                                    </Button>
                                                  </Box>
                                                  <Box mt={3}>
                                                    <FormInputAreaComponent
                                                      label="Gothram"
                                                      name="gothram"
                                                      id="gothram"
                                                      onChange={handleChange}
                                                      rows={5}
                                                      error={errors.gothram ? errors.gothram : ''}
                                                    />
                                                  </Box>
                                                  <div
                                                    style={{
                                                      marginBottom: 10,
                                                      fontSize: 14,
                                                      fontWeight: 'bold',
                                                      marginLeft: 150,
                                                    }}
                                                  >
                                                    INFORMATION
                                                  </div>
                                                  <ul
                                                    style={{
                                                      fontSize: 12,
                                                      fontWeight: 'bold',
                                                      marginLeft: 30,
                                                    }}
                                                  >
                                                    <li>Membership value: $1000.</li>
                                                    <li>
                                                      Referred to as a Life Member of Sri Ayyappa
                                                      Temple of Tampa Inc.
                                                    </li>
                                                    <li>
                                                      Participate in the SASTA Annual General Body
                                                      Meeting.
                                                    </li>
                                                    <li>
                                                      Eligible to participate in the SASTA General
                                                      Body Elections.
                                                    </li>
                                                  </ul>
                                                </div>
                                              )}
                                            />
                                            <Box display="flex" justifyContent="center">
                                              <Grid item xs={12} sm={6}>
                                                <Button
                                                  type="submit"
                                                  color="primary"
                                                  variant="contained"
                                                  style={{
                                                    fontSize: 14,
                                                  }}
                                                  fullWidth
                                                  disableTouchRipple
                                                  disableElevation
                                                  disabled={isLoading}
                                                >
                                                  Pay Now
                                                </Button>
                                              </Grid>
                                            </Box>
                                          </Form>
                                        );
                                      }}

                                      <ul>
                                        <li>Membership value: $1000.</li>
                                        <li>
                                          Referred to as a Life Member of Sri Ayyappa Temple of
                                          Tampa Inc.
                                        </li>
                                        <li>
                                          Participate in the SASTA Annual General Body Meeting.
                                        </li>
                                        <li>
                                          Eligible to participate in the SASTA General Body
                                          Elections.
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              />
                              <Box display="flex" justifyContent="center">
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    style={{
                                      fontSize: 14,
                                    }}
                                    fullWidth
                                    disableTouchRipple
                                    disableElevation
                                    disabled={isLoading}
                                  >
                                    Pay Now
                                  </Button>
                                </Grid>
                              </Box>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </div>
    </FormWrapperComponent>
  );
};

export default LifeTimeMembershipComponent;
